/** @import modules */

import { useTranslation }           from "react-i18next";

import { useParams, useNavigate }   from "react-router-dom";

/** @import hooks */

import { TyresNavigator }           from "../../../../../Hooks/TyresNavigator";

/** @import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns OEM filter component
 * 
 */

const OEM = ( { attributes } ) => { const 

    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    Filter = ( event ) => { const 

        object = { f : 12, l: null, a: null, b: null, sp: ( event.target.value === "default" ) ? null : event.target.value };

        return navigate (  
            
            `/shop/products/tyres/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    };

    /** @return component */

    return ( <div className="content manufacturers">

        <label className="select shadow-select">

            <select onChange={ e => Filter ( e ) } defaultValue={ new URLSearchParams ( parameters ).get ( "sp" ) || "default" }>

                <option value="default">

                    { t ( "select_option" ) }

                </option>

                { attributes.map ( ( option, key ) => <option 
                    
                    key={ key } 
                    
                    value={ option.value }>

                        { option.text }

                </option> ) }

            </select>

            <span>
                
                <BsFillCaretDownFill />
                
            </span>

        </label>

    </div> );

}

/** @export OEM */
 
export default OEM;