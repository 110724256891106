/** @import modules */

import { useTranslation }               from "react-i18next";

import { useNavigate, useParams }       from "react-router-dom";

/** @import hooks */

import { BatteriesNavigator }           from "../../../../../Hooks/BatteriesNavigator";

/** @import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns voltages filter component
 * 
 */

const Voltages = ( { attributes } ) => { const { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    LoadSeasons = event => { const 

        object = { f : 12, l : null, a : null, b : null, v : params.get ( "v" ) ? params.get ( "v" ).split ( "," ) : [] };

        if ( event.target.checked ) { object.v.push ( event.target.value );

            return navigate (  
            
                `/shop/products/batteries/${ 
                
                    new URLSearchParams ( BatteriesNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.v.splice ( params.get ( "v" ).split ( "," ).indexOf ( event.target.value ), 1);

            return navigate (  
            
                `/shop/products/batteries/${ 
                
                    new URLSearchParams ( BatteriesNavigator ( parameters, object ) ).toString () }` );

        }

    };

    /** @returns component */

    return ( <div className="content voltages">

        { attributes.map ( ( voltage, index ) => <label htmlFor={ voltage.value } className="checkbox" key={ index }>

            <input 

                type="checkbox" 

                id={ voltage.value } 

                value={ voltage.value } 

                onChange={ e => LoadSeasons ( e ) } 
                
                checked={ params.get ( "v" ) && ( params.get ( "v" ).split ( "," ).indexOf ( voltage.value ) !== -1 ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="name">

                { t ( voltage.text ) }

            </span>

            </label> ) }

    </div> );

}

/** Export component */
 
export default Voltages;