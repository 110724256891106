import { useTranslation } from "react-i18next";

import { useState, useEffect, useContext } from "react";

import { CartContext } from "../../../Contexts/CartContext";

import OrderSummary from "../../../Models/OrderSummary";

import Collection from "../../../Models/CartProducts";

const Summary = () => { const { t } = useTranslation (),

    orderSummary = OrderSummary (),

    { cart } = useContext ( CartContext ),

    items = Collection (),

    [ price,    setPrice    ] = useState ( t ( "calculating" ) ),

    [ vat,      setVAT      ] = useState ( t ( "calculating" ) ),

    [ total,    setTotal    ] = useState ( t ( "calculating" ) ),

    Items = () => {

        if ( items.loading ) return <></>;

        return items.data.product.edges.map ( ( product, index ) => <div className="row" key={ index }>

            <div className="text items">

                <span className="manufacturer">

                    { product.node.manufacturer.name }

                </span>

                { ` ${ product.node.model }` }

                <span className="quantity">

                    { ` x ${ cart.find( x => x.id === product.node.id ).qty }` }

                </span>

            </div>

            <div className="value">

                { parseFloat ( product.node.priceList.original_price * cart.find( x => x.id === product.node.id ).qty ).toFixed ( 2 ) } &euro;

            </div>

        </div> )

    };

    useEffect ( () => {

        if ( ! orderSummary.loading ) { const 

            totals = Object.values ( orderSummary.data.orderPriceCalculator ).reduce ( ( a, b ) => a + parseFloat ( b.price ), 0 );

            setPrice    ( ( totals - ( totals * 0.21 ) ).toFixed ( 2 )      );

            setVAT      ( ( totals * 0.21 ).toFixed ( 2 )                   );

            setTotal    ( totals.toFixed ( 2 )                              );

        }

    }, [ orderSummary.loading, orderSummary.data ] );

    /** @returns */

    return ( <div className="sector summary">

        <div className="title">

            { t ( "order_summary" ) }

        </div>

        <div className="table">

            <Items />

            <div className="row">

                <div className="text">

                    { t ( "cart_subtotals" ) }

                </div>

                <div className="value">

                    { price } &euro;

                </div>

            </div>

            <div className="row">

                <div className="text">

                    { t ( "cart_vat" ) }

                </div>

                <div className="value">

                    { vat } &euro;

                </div>

            </div>

            <div className="row">

                <div className="text">

                    { t ( "cart_totals" ) }

                </div>

                <div className="value">

                    { total } &euro;

                </div>

            </div>

        </div>

    </div> );

}

/** @exports Summary */
 
export default Summary;