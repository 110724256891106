/** Import components */

import MobileNavClose       from "./Close";

import MobileNavSale        from "./Sale";

import MobileNavActions     from "./Actions";

import MobileNavShop        from "./Shop";

import MobileNavBusiness    from "./Business";

import MobileNavContacts    from "./Contacts";

import MobileNavLocation    from "./Location";

/**
 * 
 * @param {attributes} param0 
 * 
 * @returns Mobile navigation component
 * 
 */

const MobileNavigation = ( { attributes } ) => {

    /** Return component */

    return ( <div className="mobile-navigation-wrapper">

        <div className="mobile-navigation-overflow" 
        
            onClick={ () => attributes.set ( false ) }>

        </div>
    
        <div className="mobile-navigation" id="mobile-navigation">

            <MobileNavClose action={ { set: attributes.set } } />

            <MobileNavSale action={ { set: attributes.set } } />

            <MobileNavActions action={ { set: attributes.set } } />

            <MobileNavShop action={ { set: attributes.set } } />

            <MobileNavBusiness />

            <MobileNavContacts />

            <MobileNavLocation />
        
        </div>
    
    </div>);

}

/** Export component */
 
export default MobileNavigation;