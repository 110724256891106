/**
 * 
 * @param { price } param0 
 * 
 * @returns Single product price component
 * 
 */

const Price = ( { price } ) => {

    /** Return component */

    return ( <div className="price">

        &euro; { price }

    </div> );

}

/** Export component */
 
export default Price;