/** Import modules */

import { useTranslation } from "react-i18next";

/**
 * 
 * @returns Mobile navigation contacts component
 * 
 */

const MobileNavContacts = () => { const { t } = useTranslation ();

    /** Return component */

    return (<div className="contacts">

        { t ( "call" ) } +371 26 60 62 62

    </div>);
    
}

/** Export component */
 
export default MobileNavContacts;