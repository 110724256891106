/** Import modules */

import { useTranslation }       from "react-i18next";

/** Import components */

import BlogLayout               from "../../Components/Blog/Layout";

import BlogCollection           from "../../Components/Blog/Collection";

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

/**
 * 
 * @returns Blog page
 * 
 */

const Blog = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "blog" ), URI: "/blog" }

        ] } />

        <BlogLayout title={ t ( "blog" ) }>

            <BlogCollection />

        </BlogLayout>
        
    </div> );

}

/** Export component */
 
export default Blog;