/** Import modules */

import { useNavigate, useParams }       from "react-router-dom";

import { useEffect, useState }          from "react";

import { useTranslation }               from "react-i18next";

/** Import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** Import icons */

import { FaCheck }                      from "react-icons/fa";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Manufacturers filter component
 * 
 */

const Manufactures = ( { attributes } ) => { const 

    { t }                   = useTranslation    (),

    navigate                = useNavigate       (),

    { parameters }          = useParams         (),

    params                  = new URLSearchParams ( parameters ),

    [ search, setSearch ]   = useState ( "" ),

    /** On manufacturer change update the query */

    LoadManufacturers = event => { const 

        object = { f : 12, l : null, a : null, b : null, m : params.get ( "m" ) ? params.get ( "m" ).split ( "," ) : [] };

        if ( event.target.checked ) { object.m.push ( event.target.value );

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.m.splice ( params.get ( "m" ).split ( "," ).indexOf ( event.target.value ), 1 );

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

        }

    }

    /** Filter manufactures by keyword */

    useEffect ( () => { const collection = document.querySelectorAll ( "#collection label" );

        for ( let index = 0; collection.length > index; index++ ) { const 
            
            current = collection[ index ].querySelector ( "input" );

            if ( current.defaultValue.toUpperCase ().includes ( search.toUpperCase () ) ) {

                collection[ index ].style.display = "";

            } else { collection[ index ].style.display = "none"; }

        }

    }, [ search ] )

    /** Return component */

    return ( <div className="content manufacturers">

        <div className="attribute-filter">

            <input 
            
                type="text" 
            
                placeholder={ t ( "input_name_search" ) } 
            
                value={ search } 
            
                onChange={ e => setSearch ( e.target.value ) } />

            <div className="collection-container">

                <div className="collection" id="collection">

                    { attributes.map ( ( manufacturer, index ) => <label 
                    
                        htmlFor={ manufacturer.value } 
                        
                        className="checkbox" 
                        
                        key={ index }>

                        <input 

                            type="checkbox" 

                            id={ manufacturer.value } 

                            value={ manufacturer.text } 

                            onChange={ e => LoadManufacturers ( e ) } 
                            
                            checked={ params.get ( "m" ) && ( params.get ( "m" ).split ( "," ).indexOf ( manufacturer.text ) !== -1 ) } />

                        <span className="check">

                            <span className="checked">

                                <FaCheck />

                            </span>

                        </span>

                        <span className="name">

                            { manufacturer.text.charAt ( 0 ).toUpperCase () + manufacturer.text.slice ( 1 ) }

                        </span>

                    </label> ) }

                </div>

            </div>

        </div>

    </div> );

}

/** Export component */
 
export default Manufactures;