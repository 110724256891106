const Terminal = () => {

    /** @returns */

    return ( <div className="terminal">

        <div className="select-option-placeholder">

            Terminal operator API Placeholder

        </div>

    </div> );

}

/** @exports Terminal */
 
export default Terminal;