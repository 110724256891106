import { gql } from '@apollo/client';

const 

REMOVE_APPOINTMENT = gql`mutation RemoveAppointment ( $uuid: String! ) {

    destroyAppointmentByUuid ( input: { uuid: $uuid } ) {

        clientMutationId

        destroyed

    }

}`,

ADD_APPOINTMENT = gql`mutation CreateAppointment (

        $transportType: String!,

        $startTime: ISO8601DateTime!,

        $endTime: ISO8601DateTime!,

        $autoNumber: String!,

        $autoBrand: String!,

        $autoModel: String!,

        $fullName: String!,

        $phoneNumber: String!,

        $email: String!,

        $notes: String,

        $tiresStored: Boolean!,

        $tiresBought: Boolean!,

        $tiresBoughtNotes: String,

        $tiresStoredNotes: String

    ) {

    createAppointment (

        input: {

            transportType: $transportType, 

            startTime: $startTime, 

            endTime: $endTime, 

            autoNumber: $autoNumber, 

            autoBrand: $autoBrand, 

            autoModel: $autoModel, 

            fullName: $fullName, 

            phoneNumber: $phoneNumber, 

            email: $email, 

            tiresStored: $tiresStored, 

            tiresBought: $tiresBought,

            tiresBoughtNotes: $tiresBoughtNotes,

            tiresStoredNotes: $tiresStoredNotes,

            notes: $notes

        }

    ) {

        appointment {

            id,

            destroyUuid

        },

        activerecordErrors

    }

}`;

/** @export mutations */

export { ADD_APPOINTMENT, REMOVE_APPOINTMENT }