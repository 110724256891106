/**
 * 
 * @param { product } param0 
 * 
 * @returns Single product mobile title component
 * 
 */

const Title = ( { product } ) => {

    /** @return component */

    return ( <div className="mobile-title">

        <div className="manufacturer">

            { product.manufacturer.name.toUpperCase () }

        </div>

        <div className="model">

            { product.model }

        </div>

    </div> );

}

/** @export component */
 
export default Title;