/** @import modules */

import { useTranslation }       from "react-i18next";

/**
 * 
 * @returns Contents datasets
 * 
 */

const 

ContentsDatasets = () => { const { t } = useTranslation ();

    /** @return datasets */

    return {

        /** Policies routes */

        Routes: [

            { 
                
                name    : t ( "about_us" ), 
            
                URI     : "/content/about" },

            // { 
                
            //     name    : t ( "delivery_information" ), 
            
            //     URI     : "/content/delivery" },

            { 
            
                name    : t ( "payments" ), 
            
                URI     : "/content/payments" },

            { 
        
                name    : t ( "collaboration" ), 
            
                URI     : "/content/collaboration" },

                { 
        
                    name    : t ( "gallery" ), 
                
                    URI     : "/content/gallery" }

        ]

    }

}

/** @export datasets */

export default ContentsDatasets;