/** @import modules */

import { useNavigate, useParams }       from "react-router-dom";

/** @import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** @import icons */

import { FaCheck }                      from "react-icons/fa";

import { MdLocalGasStation }            from "react-icons/md";

/**
 * 
 * @param { attributes } 
 * 
 * @returns wet fuel efficiency rating filter component
 * 
 */

const FuelEfficiency = ( { attributes } ) => { const 

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    LoadSeasons = event => { const 

        object = { f : 12, l : null, a : null, b : null, fe : params.get ( "fe" ) ? params.get ( "fe" ).split ( "," ) : [] };

        if ( event.target.checked ) { object.fe.push ( event.target.value );

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.fe.splice ( params.get ( "fe" ).split ( "," ).indexOf ( event.target.value ), 1);

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

        }

    };

    console.log( attributes );

    /** @return component */

    return ( <div className="content grip-index">

        { attributes.map ( ( fuel, index ) => <label htmlFor={ `fuel-${ fuel.value }` } className="checkbox index-checkbox" key={ index }>

            <input 

                type="checkbox" 

                id={ `fuel-${ fuel.value }` } 

                value={ fuel.value } 

                onChange={ e => LoadSeasons ( e ) } 
                
                checked={ params.get ( "fe" ) && ( params.get ( "fe" ).split ( "," ).indexOf ( fuel.value ) !== -1 ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="index-icon">

                <MdLocalGasStation />

            </span>

            <span className={ `name index-colorful-${ fuel.value.toLowerCase() }` }>

                { fuel.text.toUpperCase () }

            </span>

        </label> ) }

    </div> );

}

/** @export FuelEfficiency */
 
export default FuelEfficiency;