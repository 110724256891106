/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import parse                    from 'html-react-parser';

/** @import models */

import { SingleServiceModel }   from "../../Models/Services";

/** @import components */

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import Layout                   from "../../Components/Services/Layout";

import ComponentLoader          from "../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Services page
 * 
 */

const SingleService = () => { const { t } = useTranslation (),

    { serviceId } = useParams (),

    { loading, data } = SingleServiceModel ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "services" ), URI: "/content/services" },

            { name: loading ? `${ t ( "loading" ) }...` : data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.title, URI: "/content/services" }

        ] } type="basic" />

        <Layout title={ t ( "services" ) }>

            <div className="paragraph">

                { loading ? <ComponentLoader /> : parse ( data.services.edges.filter ( obj => obj.node.id === serviceId )[0].node.content ) }

            </div>

        </Layout>
        
    </div> );

}

/** @export SingleService */
 
export default SingleService;