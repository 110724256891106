const Properties = ( { product } ) => { const 

    properties = [

        { 
            
            id          : "voltage", 
        
            rawValue    : parseFloat ( product.voltage ), 
        
            text        : `${ product.voltage } V` },

        { 
            
            id          : "current", 
        
            rawValue    : parseFloat ( product.current ), 
        
            text        : `${ product.current } A` },

        { 
            
            id          : "capacity", 
        
            rawValue    : parseFloat ( product.capacity ), 
        
            text        : `${ product.capacity } Ah` }

    ];  

    /** @returns component */

    return ( <div className="properties text-product-property">

        { properties.map ( ( prop, index ) => prop.rawValue > 0 && <div className="property" key={ index }>

            { prop.text }

        </div> ) }

    </div> );

}

/** @exports Properties */
 
export default Properties;