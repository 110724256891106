/** Import modules */

import React                from 'react';

import { createRoot }       from 'react-dom/client';

import { ApolloProvider }   from "@apollo/client";

/** Import configurations */

import Apollo               from './Configs/Apollo';

import Languages            from './Configs/Languages';

/** Import components */

import Application          from './Pages/Application';

/** Import styles */

import 'swiper/css';

import "./Styles/Main.css";

import "./Styles/Mobile.css";

/** Initialize i18n languages */

Languages ();

/** Render application */

createRoot ( document.getElementById ( 'b2c-tiremarket' ) ).render (

  <ApolloProvider client={ Apollo }>

    <Application />

  </ApolloProvider>

);