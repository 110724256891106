/** Import assets */

import "../../Styles/Blog.css";

/** Import datasets */

// import BlogDatasets from "../../Datasets/Blog";

/**
 * 
 * @param {title, children} param0 
 * 
 * @returns Blog layout component
 * 
 */

const BlogLayout = ( { title, children } ) => {

    /** Return component */

    return ( <div className="content blog">

        <div className="title">
            
            <h2>{ title }</h2>
            
        </div>
    
        <div className="layout">

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default BlogLayout;