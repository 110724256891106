/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Copyrights           from "../Components/Layout/Footer/Copyrights";

import FooterInfo           from "../Components/Layout/Footer/Information";

import Gateway              from "../Components/Layout/Footer/Gateway";

import FooterNavigation     from "../Components/Layout/Footer/Navigation";

// import Subscription         from "../Components/Layout/Footer/Subscription";

/** Register datasets */

const FooterDatasets = () => { 
    
    const { t } = useTranslation (),

    /** Contacts */

    Contacts = [

        { 
            
            name        : t ( "weekdays" ), 
        
            content     : "9:00 - 18:00", 
        
            display     : "default",
        
            link        : false,
        
            URI         : null },

        { 
            
            name        : t ( "call" ), 
        
            content     : "+371 27 80 5555", 
        
            display     : "default",
        
            link        : false,
        
            URI         : null },

        { 
            
            name        : t ( "write" ), 
        
            content     : "info@tiremarket.eu", 
        
            display     : "accented",
        
            link        : false,
        
            URI         : null }

    ],

    /** Location */

    Location = [

        { 
            
            name        : t ( "address" ), 
        
            content     : t ( "address_location" ), 
        
            link        : false, 
        
            URI         : null,
        
            display     : "default" }

    ],

    /** Policies routes */

    Policies = [

        { route: "/policies/privacy-policy",        name: t ( "privacy_policy" )            },

        { route: "/policies/cookie-policy",         name: t ( "cookies" )                   },

        { route: "/policies/terms-of-use",          name: t ( "terms_and_conditions" )      }

    ],

    Contents = [

        { route: "/content/about",                  name: t ( "about_us" )                  },

        // { route: "/content/delivery",               name: t ( "delivery_information" )      },

        { route: "/content/payments",               name: t ( "payments" )                  },

        // { route: "/content/collaboration",          name: t ( "collaboration" )             },

        { route: "/blog",                           name: t ( "blog" )                      },

        { route: "/content/gallery",                name: t ( "gallery" )                   }

    ],

    Shop = [

        // { route: "/shop/products/tyres",            name: t ( "tyres" )                     },

        // { route: "/shop/products/rims",             name: t ( "rims" )                      },

        // { route: "/shop/products/batteries",        name: t ( "batteries" )                 },

        { route: "/content/services",               name: t ( "services" )                  },

        { route: "/content/montage",                name: t ( "montage" )                   },

        // { route: "/shop/products/sale",             name: t ( "sale" )                      }

    ];

    /** Footer panes */

    return [

        { render: [

            // {   content: <Subscription />, 
            
            //     id: "subscribe" },

            {   content: <Copyrights />, 
            
                id: "copyrights" }

        ] },

        { render: [

            {   content: <FooterInfo 
                
                    data={ Contacts } />, 
                
                id: "contacts" },

            {   content: <FooterInfo 
                
                    data={ Location } />, 
                
                id: "location" },

            {   content: <Gateway />, 
                
                id: "gateway" }
                
        ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Shop } />, 
                
                id: "shop" } ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Contents } />, 
                
                id: "contents" } ] },

        { render: [ 
            
            {   content: <FooterNavigation 
            
                    routes={ Policies } />, 
                
                id: "policies" } ] },

    ];



}

/** Export datasets */

export default FooterDatasets;