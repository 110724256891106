/** Import modules */

import { Link } from "react-router-dom";

/**
 * 
 * @param {data} param0 
 * 
 * @returns Footer information component
 * 
 */

const FooterInfo = ( { data } ) => {

    /** Return component */

    return (<div className="footer-information">

        <ul>

            { data.map ( ( content, index ) => 
            
                <li key={ index }>
                    
                    <div className="name">

                        { content.name }

                    </div>

                    <div className={ `content style-${ content.display }` }>

                        { ( content.link ) ? 
                        
                        <Link to={ content.URI }>{ content.content }</Link> : content.content }

                    </div>
                    
                </li>

            ) }

        </ul>

    </div>);

}

/** Export component */
 
export default FooterInfo;