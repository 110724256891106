const 

compareProperties = ( type, product ) => {

    switch ( type ) {

        case "tire" : return [

            { 
                
                name: "manufacturer", 
            
                value: ( ! product.manufacturer.name ) ? "-" : product.manufacturer.name, 
            
                slug: "manufacturer" },

            { 
                
                name: "model", 
            
                value: ( ! product.model ) ? "-" : product.model, 
            
                slug: "model" },

            { 
                
                name: "width", 
            
                value: ( ! product.width ) ? "-" : product.width, 
            
                slug: "width" },

            { 
                
                name: "height", 
            
                value: ( ! product.height ) ? "-" : product.height, 
            
                slug: "height" },

            { 
                
                name: "diameter", 
            
                value: ( ! product.diameter ) ? "-" : `R${ product.diameter }`, 
            
                slug: "diameter" },

            { 
                
                name: "season", 
            
                value: ( ! product.season ) ? "-" : product.season, 
            
                slug: "season" },

            { 
                
                name: "speed_rating", 
            
                value: ( ! product.speedRating ) ? "-" : product.speedRating, 
            
                slug: "speed_rating" },

            { 
                
                name: "load_index", 
            
                value: ( ! product.loadIndex ) ? "-" : product.loadIndex, 
            
                slug: "load_index" },

            { 
                
                name: "noise_index", 
            
                value: ( ! product.noiseIndex ) ? "-" : product.noiseIndex, 
            
                slug: "noise_index" },

            { 
                
                name: "wet_breaking", 
            
                value: ( ! product.wetBreakingEfficiency ) ? "-" : product.wetBreakingEfficiency, 
            
                slug: "wet_breaking" },

            { 
                
                name: "OE", 
            
                value: ( ! product.specifications.length ) ? "-" : product.specifications.join ( ", " ),
            
                slug: "OE" }

        ];

        case "rim" : return [

            { 
                
                name: "manufacturer", 
            
                value: ( ! product.manufacturer.name ) ? "-" : product.manufacturer.name, 
            
                slug: "manufacturer" },

            { 
                
                name: "model", 
            
                value: ( ! product.model ) ? "-" : product.model, 
            
                slug: "model" },

            { 
                
                name: "width", 
            
                value: ( ! product.width ) ? "-" : product.width, 
            
                slug: "width" },

            { 
                
                name: "diameter", 
            
                value: ( ! product.diameter ) ? "-" : `R${ product.diameter }`, 
            
                slug: "diameter" },

            { 
                
                name: "offset", 
            
                value: ( ! product.offset ) ? "-" : product.offset, 
            
                slug: "offset" },

            { 
                
                name: "pcd", 
            
                value: ( ! product.pitchCircleDiameter ) ? "-" : product.pitchCircleDiameter, 
            
                slug: "pcd" }

        ];

        case "battery" : return [

            { 
                
                name: "manufacturer", 
            
                value: ( ! product.manufacturer.name ) ? "-" : product.manufacturer.name, 
            
                slug: "manufacturer" },

            { 
                
                name: "model", 
            
                value: ( ! product.model ) ? "-" : product.model, 
            
                slug: "model" },

            { 
                
                name: "voltages", 
            
                value: ( ! product.voltage ) ? "-" : `${ product.voltage } V`, 
            
                slug: "voltages" },

            { 
                
                name: "currents", 
            
                value: ( ! product.current ) ? "-" : `${ product.current } A`, 
            
                slug: "currents" },

            { 
                
                name: "capacities", 
            
                value: ( ! product.capacity ) ? "-" : `${ product.capacity } Ah`, 
            
                slug: "capacities" }

        ];

        default : return [];

    }

};  

export {

    compareProperties

}