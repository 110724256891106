/** @import modules */

import { useTranslation }   from "react-i18next";

import { Elements } from "@stripe/react-stripe-js";

import { loadStripe } from "@stripe/stripe-js";

/** @import contexts */

import { MontageContext }   from "../../Contexts/MontageContext";

/** @import hookes */

import { MontageHook }      from "../../Hooks/Montage";

/** @import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import MontageLayout        from "../../Components/Montage/Layout";

import Type                 from "../../Components/Montage/Type";

import Forms                from "../../Components/Montage/Forms";

const stripePromise = loadStripe('pk_test_51L9kW1EjjXeZYQATsDoZRi5mK5xaoRP16pSd2qSlryogqW63YTLeB17YHMVYYJuQYAy6icYR2s2VSv9HDGiA6GRD00cBGYmFKm');

/**
 * 
 * @returns montage page
 * 
 */

const Montage = () => { const { t } = useTranslation ();

    /** @return component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "montage" ), URI: "/content/montage" }

        ] } />

        <Elements stripe={stripePromise}>

        <MontageLayout>

            <MontageContext.Provider value={ MontageHook () }>

                <Type />

                <Forms />

            </MontageContext.Provider>

        </MontageLayout>

        </Elements>
        
    </div> );

}

/** @export Montage */
 
export default Montage;