/** Import assets */

import "../../Styles/Home.css";

/** Import components */

// import HomeProductSearch    from "../../Components/Home/Search";

import HomeServices         from "../../Components/Home/Services";

// import HomeSale             from "../../Components/Home/Sale";

// import HomeBanner           from "../../Components/Home/Banner";

// import HomeTeam             from "../../Components/Home/Team";

import HomeBlog             from "../../Components/Home/Blog";

import LIAA                 from "../../Components/Home/LIAA";

const Home = () => {

    /** Render component */

    return (<div className="home">

        {/* <HomeProductSearch /> */}

        <HomeServices />

        {/* <HomeSale />

        <HomeBanner />

        <HomeTeam /> */}

        <HomeBlog />

        <LIAA />

    </div>);

}

/** Export component */
 
export default Home;