/** Import modules */

import { Link }             from "react-router-dom";

import { useTranslation }   from "react-i18next";

/** Import icons */

import { FaAngleRight }     from "react-icons/fa"

/** Import components */

// import QuickSearch          from "./Search";

/**
 * 
 * @param { collection, type } param0 
 * 
 * @returns Breadcrumbs component
 * 
 */

const Breadcrumbs = ( { collection, type } ) => { const { t } = useTranslation ();

    /** Return component */

    return (<div className="breadcrumbs-basic">

        {/* { type !== "basic" && <QuickSearch /> } */}

        <div className="list">

            <div className="item">
                    
                <div className="name">

                    <Link to="/">

                        { t ( "homepage" ) }

                    </Link>
                    
                </div>

                <div className="icon"><FaAngleRight /></div>
            
            </div>

            { collection.map ( ( route, index, element ) => <div className="collection" key={ index }>

                { ( index + 1 === element.length ) &&  
                
                    <div className="item-end">{ route.name }</div>

                }

                { ( index + 1 !== element.length ) && <div className="item">
                    
                    <div className="name">

                        <Link to={ route.URI }>

                            { route.name }

                        </Link>

                    </div>

                    <div className="icon"><FaAngleRight /></div>
                
                </div>}

            </div> ) }

        </div>

    </div>);

}

/** Export component */
 
export default Breadcrumbs;