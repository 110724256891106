/** Import modules */

import { 
    
    ApolloClient, 
    
    InMemoryCache, 
    
    HttpLink, 
    
    from }                          from "@apollo/client";

import { relayStylePagination }     from '@apollo/client/utilities';

/** Configure apollo client */

const Apollo = new ApolloClient ( {

    cache: new InMemoryCache ( {

        typePolicies: {

            Query: {

                fields: {

                    singleStringSearch: relayStylePagination ()

                }

            }

        }

    } ),

    link: from ( [

        new HttpLink ( { uri : process.env.REACT_APP_GQL_URI, credentials : process.env.REACT_APP_GQL_CRED, headers : {

            authorization : `Basic ${ window.btoa ( "tireshop:a5a0c481050d4218475cd15a8fe54957" ) }`

        } } )

    ] )

} );

/** Export apollo configuration */

export default Apollo;