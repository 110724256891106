/** @import modules */

import { Link } from "react-router-dom";

/** @import misc */

import { TyrePermalinks } from "../../../../Misc/Permalinks";

/** @import components */

import Features from "../../Tyres/Catalog/Item/Features";

import Properties from "../../Tyres/Catalog/Item/Properties";

/** @import icons */

import { AiFillEye } from "react-icons/ai";

/** @import assets */

import NoProduct from "../../../../Assets/no-tyres.jpg";

/**
 * 
 * @param { product } 
 * 
 * @returns tyre quick search item component
 * 
 */

const Tyre = ( { product } ) => { const permalink = TyrePermalinks ( product );

    /** @returns component */

    return ( <div className="tyre">

        <div className="product-image">

            <Link to={ permalink }>

                { ( ! product.currentImage ) ? 
                
                    <img src={ NoProduct } alt={ product.model } /> 
                    
                    : <img src={ product.currentImage } alt={ product.model } /> }

            </Link>

        </div>

        <div className="product-name">

            <Link to={ permalink }>

                <div className="manufacturer">

                    { product.manufacturer.name }

                </div>

                <div className="model">

                    { product.model }

                </div>

            </Link>

            <div className="size">

                { `${ product.width } | ${ product.height } | ${ product.diameter }` }

            </div>

            <div className="price">

                &euro; { parseFloat ( product.priceList.original_price ).toFixed ( 2 ) }

            </div>
            
        </div>

        <div className="product-data">

            <Properties product={ product } />

            <Features product={ product } />
                        
        </div>

        <div className="product-actions">

            <Link to={ permalink }>

                <AiFillEye />

            </Link>

        </div>

    </div> );

}

/** @exports Tyre */
 
export default Tyre;