/** @import components */

import Display from "./Layout/Display";

import Sorting from "./Layout/Sorting";

/** @import icons */

import { BsFilterSquare } from "react-icons/bs";

/**
 * 
 * @param { layout, attributes } param0 
 * 
 * @returns Product catalog actions component
 * 
 */

const Actions = ( { layout, attributes, mobile } ) => {

    /** Return component */

    return ( <div className="actions">

        <div className="mobile-filters-switch">
            
            <button onClick={ () => {

                ( mobile.view ) ? mobile.set ( false ) : mobile.set ( true );

                return mobile.element.current.classList.add ( "mobile-filters-state" );

            } }>

                <BsFilterSquare />

            </button>
            
        </div>

        <Sorting attributes={ attributes } />

        <Display layout={ { view: layout.view, set: layout.set } } />

    </div> );

}

/** Export component */
 
export default Actions;