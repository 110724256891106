/** Import assets */

import Logotype from "../../../Assets/tiremarket.png";

/**
 * 
 * @returns Copyrights component
 * 
 */

const Copyrights = () => {

    /** Return component */

    return (<div className="copyrights">

        <div className="logotype">

            <img src={ Logotype } alt="tiremarket.eu" />

        </div>

        <div className="content">

            &copy; TIRE MARKET { new Date ().getFullYear () }

        </div>

    </div>);

}

/** Export component */
 
export default Copyrights;