/** @import modules */

import { useTranslation } from "react-i18next";

const Cash = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="cash">

        { t ( "cash_payment_notice" ) }

    </div> );

}

/** @exports Cash */
 
export default Cash;