/** @import modules */

import { useTranslation }       from "react-i18next";

import { useContext }           from "react";

import { OrderParameters }      from "../../../Models/Checkout";

import { CheckoutContext }      from "../../../Contexts/Checkout";

import ComponentLoader          from "../../Layout/Loaders/Loader";

import Radios                   from "./Radios";

import PaymentMethods           from "./Payments/Methods";

const Payments = () => { const { t } = useTranslation (),

    { Payment } = useContext ( CheckoutContext ),

    { data, loading } = OrderParameters (),

    Options = () => {

        if ( loading ) return <ComponentLoader />

        const paymentMethods = [];

        data.orderOptions.payment_method.map ( ( order ) => paymentMethods.push ( {

            id: order.value, 
            
            value: order.value, 
            
            label: t ( order.value )

        } ) );

        return <Radios 
            
            parameters={ paymentMethods } 
        
            name="payment_method" 
        
            action={ Payment } />

    };

    /** @returns */

    return ( <div className="sector payments">

        <div className="title">

            { t ( "payment" ) }

        </div>

        <Options />

        { ! Payment.view && <div className="select-option-placeholder">

            { t ( "select_payment" ) }

        </div> }

        { Payment.view && <PaymentMethods method={ Payment.view } /> }

    </div> );

}

/** @exports Payments */
 
export default Payments;