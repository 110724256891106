/** @import modules */

import { useQuery }             from "@apollo/client";

/** @import queries */

import { OrderOptions }         from "../Queries/Orders";

/**
 * 
 * @param { parameters } 
 * 
 * @returns checkout options
 * 
 */

const OrderParameters = () => { const

    { loading, data } = useQuery ( OrderOptions );

    /** @return model */

    return { loading, data }

};

/** @export models */

export { 
    
    OrderParameters

};