/** Import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import parse                    from "html-react-parser";

import { useNavigate }          from "react-router-dom";

/** Import model */

import Post                     from "../../Models/SingleArticle";

/** Import components */

import BlogLayout               from "../../Components/Blog/Layout";

import Breadcrumbs              from "../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Blog page
 * 
 */

const Article = () => { const { t } = useTranslation (),

    navigate = useNavigate (),

    articleId = useParams ().articleid,

    { loading, data } = Post ( articleId ),

    Content = () => {

        if ( loading ) return <ComponentLoader />

        if ( ! data.blogItem.isPublished ) return navigate ( "/blog" );

        return <div className="post-content">

            { parse ( data.blogItem.content ) }

        </div>

    }

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "blog" ), URI: "/blog" },

            { name: ( loading ) ? `${ t ( "loading" ) }...` : data.blogItem.title, URI: `/blog/article/${ articleId }` }

        ] } type="basic" />

        <BlogLayout title={ ( loading ) ? `${ t ( "loading" ) }...` : data.blogItem.title }>

            <Content />

        </BlogLayout>
        
    </div> );

}

/** Export component */
 
export default Article;