/** @import module */

import cookies                          from "js-cookie";

import { NavLink }                      from "react-router-dom";

/** @import models */

import { NavigationModel }              from "../../../Models/Services";

/** @import components */

import ComponentLoader                  from "../../Layout/Loaders/Loader";

/**
 * 
 * @returns services navigation widget component
 * 
 */

const Navigation = () => { const { loading, data } = NavigationModel (),

    /** @returns services collection */

    Collection = ( { object, status } ) => {

        /** load component fallback */

        if ( status ) return <ComponentLoader />

        /** filter by active entries and language */

        let filter = object.services.edges.filter ( obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isActive === true );

        if ( filter.length === 0 ) {

            filter = object.services.edges.filter ( obj => obj.node.language === "en" && obj.node.isActive === true );

        }

        return filter.map ( ( service, index ) => <li key={ index }>

            <NavLink to={ `/content/services/${ service.node.id }` } className={ ( { isActive } ) => ( isActive ? 'active' : 'inactive' ) }>

                { service.node.title }

            </NavLink>

        </li> );

    }

    /** @return component */

    return ( <div className="navigation">

        <ul>

            <Collection object={ data } status={ loading } />

        </ul>

    </div> );

}

/** @export Navigation */
 
export default Navigation;