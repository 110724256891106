/** @import modules */

import { useContext } from "react";

import { CheckoutContext } from "../../../../Contexts/Checkout";

import Fields from "../Fields";

const DPD = () => { const 

    { Country, Town, Region, Street, StreetNo, Postal } = useContext ( CheckoutContext ),

    addressForm = [

        { type: "select", id: "country", name: "country", text: "country", options: [

            { value: "", text: "-- Select --" },

            { value: "lv", text: "Latvia" },

            { value: "lt", text: "Lithuania" },

            { value: "ee", text: "Estonia" },

        ], action: Country },

        { type: "text", id: "town", name: "town", text: "town", action: Town },

        { type: "text", id: "region", name: "region", text: "region", action: Region },

        { type: "text", id: "street", name: "street", text: "street", action: Street },

        { type: "text", id: "streetno", name: "streetno", text: "streetno", action: StreetNo },

        { type: "text", id: "postal", name: "postal", text: "postal", action: Postal }


    ];

    /** @returns */

    return ( <div className="dpd">

        <Fields parameters={ addressForm } />

    </div> );

}

/** @exports DPD */
 
export default DPD;