/** Import modules */

import { useTranslation }           from "react-i18next";

import { useParams, useNavigate }   from "react-router-dom";

/** Import hooks */

import { TyresNavigator }           from "../../../../../Hooks/TyresNavigator";

/** Import datasets */

import { SizeFilters }              from "../../../../../Datasets/Tyres";

/** Import icons */

import { BsFillCaretDownFill }      from "react-icons/bs";

import { AiFillInfoCircle }         from "react-icons/ai";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Tyre size filter component
 * 
 */

const Size = ( { attributes } ) => { const 
    
    { t }               = useTranslation    (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    Filter = ( event, id ) => { const 

        object = { f : 12, l: null, a: null, b: null };

        if ( id === "w" ) object.w = ( event.target.value === "default" ) ? null : event.target.value;

        if ( id === "h" ) object.h = ( event.target.value === "default" ) ? null : event.target.value;

        if ( id === "d" ) object.d = ( event.target.value === "default" ) ? null : event.target.value;

        return navigate (  
            
            `/shop/products/tyres/${ 
            
                new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

    };

    /** Return component */

    return ( <div className="content sizes">

        { SizeFilters ( attributes ).map ( ( size, index ) => <div className="select-filter" key={ index }>

            <div className="title">

                <div className="name">
                    
                    { size.name }
                    
                </div>

                <div className="info">

                    <span className="filter-tooltip">
                        
                        { size.tooltip }
                        
                    </span>

                    <AiFillInfoCircle />

                </div>

            </div>

            <label className="select shadow-select">

                <select onChange={ e => Filter ( e, size.id ) } defaultValue={ new URLSearchParams ( parameters ).get ( size.id ) || "default" }>

                    <option value="default">

                        { t ( "select_option" ) }

                    </option>

                    { size.options.map ( ( option, key ) => <option 
                        
                        key={ key } 
                        
                        value={ ( size.type === "INT" ? parseInt ( option.value ) : parseFloat ( option.value ) ) }>

                            { size.type === "INT"   && parseInt     ( option.text ) }

                            { size.type === "FLOAT" && parseFloat   ( option.text ) }

                    </option> ) }

                </select>

                <span>
                    
                    <BsFillCaretDownFill />
                    
                </span>

            </label>

        </div> ) }

    </div> );

}

/** Export component */
 
export default Size;