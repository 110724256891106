/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import PoliciesLayout       from "../../Components/Policies/Layout";

import cookies from "js-cookie";

import { useQuery } from "@apollo/client";

import { PAGES } from "../../Queries/Pages";

import parse                    from "html-react-parser";

/**
 * 
 * @returns Cookie policy page
 * 
 */

const CookiesPolicy = () => { const { t } = useTranslation ();

const { data, loading } = useQuery ( PAGES, { variables: {

    language: cookies.get ( 'i18next' ) || "lv",

    pageType: "cookies_policy"

} } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "cookies" ), URI: "/policies/privacy-policy" }

        ] } />

        <PoliciesLayout title={ t ( "cookies" ) }>

        { loading ? "..." : typeof data != "undefined" && Object.keys(data.frontendPages.edges).length && parse ( data.frontendPages.edges[0].node.content ) }

        </PoliciesLayout>
        
    </div> );

}

/** Export component */
 
export default CookiesPolicy;