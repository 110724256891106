const 

ErrorsCheck = data => { const 

    local_errors = [];

    if ( ! data.vehicle[ 0 ].value.view.length  ) { local_errors.push ( { err : "missing_numberplate"   } ); }

    if ( ! data.vehicle[ 1 ].value.view.length  ) { local_errors.push ( { err : "missing_brand"         } ); }

    if ( ! data.vehicle[ 2 ].value.view.length  ) { local_errors.push ( { err : "missing_model"         } ); }

    if ( ! data.customer[ 0 ].value.view.length ) { local_errors.push ( { err : "missing_name"          } ); }

    if ( ! data.customer[ 1 ].value.view.length ) { local_errors.push ( { err : "missing_phone"         } ); }

    if ( ! data.customer[ 2 ].value.view.length ) { local_errors.push ( { err : "missing_email"         } ); }

    if ( data.type.view !== "cooler" ) {

        if ( data.stored.view === true ) {

            if ( ! data.storedNotes.view.length ) { local_errors.push ( { err : "missing_storage_number" } ); }

        }

        if ( data.bought.view === true ) {

            if ( ! data.boughtNotes.view.length ) { local_errors.push ( { err : "missing_order_number" } ); }

        }

    }

    /** Update error state */

    if ( local_errors.length ) {

        data.state.set ( local_errors );

    }

    /** @returns errors */

    return local_errors;

};

/** @exports */

export {

    ErrorsCheck

}