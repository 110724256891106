/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import Layout               from "../../Components/Services/Layout";

import List                 from "../../Components/Services/Services";

/**
 * 
 * @returns Services page
 * 
 */

const Services = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "services" ), URI: "/content/services" }

        ] } />

        <Layout title={ t ( "services" ) }>

            <List />

        </Layout>
        
    </div> );

}

/** Export component */
 
export default Services;