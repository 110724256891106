/** @import modules */

import { useNavigate, useParams }       from "react-router-dom";

/** @import hooks */

import { TyresNavigator }               from "../../../../../Hooks/TyresNavigator";

/** @import icons */

import { FaCheck }                      from "react-icons/fa";

import { BsCloudRainFill }              from "react-icons/bs";

/**
 * 
 * @param { attributes } 
 * 
 * @returns wet grip rating filter component
 * 
 */

const Grip = ( { attributes } ) => { const 

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    params              = new URLSearchParams ( parameters ),

    LoadSeasons = event => { const 

        object = { f : 12, l : null, a : null, b : null, wg : params.get ( "wg" ) ? params.get ( "wg" ).split ( "," ) : [] };

        if ( event.target.checked ) { object.wg.push ( event.target.value );

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );
        
        } else { object.wg.splice ( params.get ( "wg" ).split ( "," ).indexOf ( event.target.value ), 1);

            return navigate (  
            
                `/shop/products/tyres/${ 
                
                    new URLSearchParams ( TyresNavigator ( parameters, object ) ).toString () }` );

        }

    };

    console.log( attributes );

    /** @return component */

    return ( <div className="content grip-index">

        { attributes.map ( ( grip, index ) => <label htmlFor={ grip.value } className="checkbox index-checkbox" key={ index }>

            <input 

                type="checkbox" 

                id={ grip.value } 

                value={ grip.value } 

                onChange={ e => LoadSeasons ( e ) } 
                
                checked={ params.get ( "wg" ) && ( params.get ( "wg" ).split ( "," ).indexOf ( grip.value ) !== -1 ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="index-icon">

                <BsCloudRainFill />

            </span>

            <span className={ `name index-colorful-${ grip.value }` }>

                { grip.text.toUpperCase () }

            </span>

        </label> ) }

    </div> );

}

/** @export Grip */
 
export default Grip;