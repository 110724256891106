/** Import modules */

import { 
    
    useNavigate, 
    
    useParams }                 from "react-router-dom";

/** Import datasets */

import { ProductsPagination }   from "../../../../Datasets/Products";

/** Import hooks */

import { TyresNavigator }       from "../../../../Hooks/TyresNavigator";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns Product pagination component
 * 
 */

const Pagination = ( { pageInfo } ) => { const 
    
    navigate                = useNavigate   (),
    
    { parameters }          = useParams     ();

    /** Return component */

    return ( <div className="pagination">

        { ProductsPagination ( pageInfo ).map ( ( button, index ) => button.disableState && <button 
        
        key={ index } 
        
        onClick={ () => navigate (

            `/shop/products/tyres/${ new URLSearchParams ( TyresNavigator ( parameters, button.query ) ).toString () }`

        ) } disabled={ button.state }>

            { button.icon }

        </button> ) }

    </div> );

}

/** Export component */
 
export default Pagination;