/** @import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { useState }             from "react";

/** @import hooks */

import { qtySum }               from "../../../Hooks/QtySum";

/** @import models */

import Product                  from "../../../Models/SingleBattery";

/** @import components */

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

import SingleRimLayout          from "../../../Components/Products/Batteries/Single/Layout";

import Description              from "../../../Components/Products/Batteries/Single/Description";

import SingleProductData        from "../../../Components/Products/Batteries/Single/Product";

import Price                    from "../../../Components/Products/Batteries/Single/Data/Price";

import Stock                    from "../../../Components/Products/Batteries/Single/Data/Stock";

import Properties               from "../../../Components/Products/Batteries/Catalog/Properties";

import Cart                     from "../../../Components/Products/Batteries/Single/Data/Cart";

import Compare                  from "../../../Components/Products/Batteries/Single/Data/Compare";

import Title                    from "../../../Components/Products/Batteries/Single/Data/Title";

import Specifications           from "../../../Components/Products/Layout/Single/Specifications";

import ActionNotification       from "../../../Components/Products/Batteries/Single/Data/Notifications";

import Delivery                 from "../../../Components/Products/Shared/Single/Delivery";

const SingleBattery = () => { const 
    
    { t }                   = useTranslation    (),

    productId               = useParams         ().productId,

    { loading, data }       = Product           ( productId ),

    [ 
        
        cartNotification, 
        
        setCartNotification ] = useState ( [] ),

    [ 
    
        compareNotification, 
        
        setCompareNotification ] = useState ( [] ),

    /** @returns Loaded product data and components */

    LoadProduct = () => {

        if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node;

        return <SingleRimLayout data={ product }>

            <SingleProductData data={ { 
                
                image : product.awsImageUrl, 
                
                model : `${ product.manufacturer.name.toUpperCase () } ${ product.model }` } }>

                    <Title product={ product } />

                    <Price price={ parseFloat ( product.productVendors[0].price ).toFixed ( 2 ) } />

                    <Stock quantity={ qtySum ( product.productVendors ) } />

                    <Properties product={ product } />

                    <Delivery product={ product } />

                    { cartNotification.length > 0 && 

                        <ActionNotification data={ cartNotification } /> }

                    <Cart product={ product } notifications={ { view: cartNotification, set: setCartNotification } } />

                    { compareNotification.length > 0 && 

                        <ActionNotification data={ compareNotification } /> }

                    <Compare productId={ product.id } notifications={ { view: compareNotification, set: setCompareNotification } } />

            </SingleProductData>

            <Specifications data={ [

                { name: t ( "manufacturer" ), value: product.manufacturer.name, style: "uppercase" },

                { name: t ( "model" ), value: product.model, style: "regular" },

                { name: t ( "voltages" ), value: `${ product.voltage } V`, style: "regular" },

                { name: t ( "currents" ), value: `${ product.current } A`, style: "regular" },

                { name: t ( "capacities" ), value: `${ product.capacity } Ah`, style: "regular" }

            ] } />

            <Description description={ product.description } />

        </SingleRimLayout>

    };

    /** @returns component */

    return ( <div className="container max-content-width">

        { ! loading && 

        <Breadcrumbs collection={ [

            { name: t ( "batteries" ), URI: "/shop/products/batteries" },

            { name: data.product.edges[ 0 ].node.manufacturer.name.toUpperCase (), URI: `/shop/products/batteries/${

                new URLSearchParams ( { f: 12, m: data.product.edges[ 0 ].node.manufacturer.name } ).toString ()

            }` },

            { name: data.product.edges[ 0 ].node.model, URI: "/shop/products/batteries" },

        ] } type="basic" /> }

        <LoadProduct />

    </div> );

}

/** Export component */
 
export default SingleBattery;