/** @import modules */

import { useTranslation }           from "react-i18next";

import { useContext }               from "react";

/** @import contexts */

import { CompareContext }           from "../../../../../Contexts/CompareContext";

/** @import icons */

import { MdOutlineCompareArrows }   from "react-icons/md";

/**
 * 
 * @param { productId } param0 
 * 
 * @returns Single product add to compare component
 * 
 */

const Compare = ( { productId, notifications } ) => { const

    { t } = useTranslation (),

    { compare, setCompare } = useContext ( CompareContext ),

    /** @returns Add product to a compare local storage */

    addToCompare = ( id ) => { const 

        tracker = compare.reduce ( ( counter, object ) => {

            if ( object.type === "rim" ) counter += 1;

            return counter;

        }, 0 );

        if ( tracker === 4 ) { notifications.set ( [ { type: "error", msg: "add_compare_maxes" } ] ); return null };

        if ( compare.find ( x => x.id === id ) ) { notifications.set ( [ { type: "error", msg: "add_compare_exists" } ] ); return null };

        notifications.set ( [ { type: "success", msg: "add_compare_success" } ] )

        return setCompare ( [...compare, { id, type : "rim" } ] );

    };

    console.log( compare );

    /** @returns component */

    return ( <div className="add-to-compare">

        <button onClick={ () => addToCompare ( productId ) }>

            { t ( "add_to_compare" ) }

            <span>
                
                <MdOutlineCompareArrows />
                
            </span>

        </button>

    </div> );

}

/** @export component */
 
export default Compare;