/** Import modules */

import { useQuery }             from "@apollo/client";

/** Import queries */

import { Appointments }         from "../Queries/Appointments";

import { Appointment }          from "../Queries/Appointments";

import { AppointmentExtended }  from "../Queries/Appointments";

/**
 * 
 * @param { parameters } 
 * 
 * @returns appointments list model
 * 
 */

const AppointmentsModel = ( parameters ) => { const

    { loading, data } = useQuery ( Appointments, { variables: {

        transportType   : parameters.type,

        year            : parseInt ( parameters.year ),

        month           : parseInt ( parameters.month ),

        day             : parseInt ( parameters.day )

    } } );

    /** @return model */

    return { loading, data }

};

/**
 * 
 * @param { uuid }
 *  
 * @returns shortened appointment data list
 * 
 */

const AppointmentModel = ( uuid ) => { const 

    { loading, data } = useQuery ( Appointment, { variables: { uuid } } );

    /** @return model */

    return { loading, data }

};

const ExtendedAppointmentModel = uuid => { const 

    { loading, data } = useQuery ( AppointmentExtended, { variables: { uuid } } );

    /** @return model */

    return { loading, data } 

};

/** @export models */

export { 
    
    AppointmentsModel, 
    
    AppointmentModel,

    ExtendedAppointmentModel

};