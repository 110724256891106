/** Import modules */

import i18next                          from "i18next";

import cookies                          from "js-cookie";

import { useState }                     from "react";

/** Import icons */

import { FaGlobeEurope, FaCaretDown }   from "react-icons/fa";

/**
 * 
 * @returns Language selector component
 * 
 */

const LanguageSelector = () => { const 

    Languages = [

        { code: "lv", name: "Latviešu" },

        { code: "lt", name: "Lietuvių" },

        { code: "et", name: "Eesti keel" },

        { code: "en", name: "English" },

        { code: "de", name: "Deutsch" },

        { code: "sv", name: "Svenska" },

        { code: "no", name: "Norsk" },

        { code: "da", name: "Dansk" },

        { code: "fi", name: "Suomalainen" },

        { code: "ru", name: "Русский" },

    ],

    [ language, setLanguage ]   = useState ( cookies.get ( 'i18next' ) || "lv" ),
    
    [ dropdown, setDropdown ]   = useState ( false );

    /** Return component */

    return (<div className="language-selector">

        <div className="selector" onClick={ () => ( dropdown ? setDropdown ( false ) : setDropdown ( true ) ) }>

            <FaGlobeEurope />

            <span>{ language }</span>

            <FaCaretDown />

        </div>

        { dropdown && <div className="dropdown">
        
            <ul>

                { Languages.map ( ( { code, name } ) => (

                    <li key={ code }>

                        <button 

                            onClick={ () => { 
                                
                                i18next.changeLanguage ( code ); 
                                
                                setLanguage ( code );

                                setDropdown ( false );
                            
                            } }
                        
                            disabled={ code === language }>

                            { name }

                        </button>

                    </li>

                ) ) }

            </ul>

        </div> }

    </div>);

}

/** Export component */
 
export default LanguageSelector;