import { gql } from "@apollo/client";

const 

/** Appointment by uuid */

Appointment = gql`query Appointment ( $uuid: String! ) {

    appointmentByUuid ( uuid: $uuid ) {

        transportType

        autoBrand

        autoModel

        startTime

        endTime

        serviceBox

    }

}`,

AppointmentExtended = gql`query Appointment ( $uuid: String! ) {

    appointmentByUuid ( uuid: $uuid ) {

        transportType

        autoBrand

        autoModel

        autoNumber

        diskDiameter

        destroyUuid

        id

        email

        fullName

        phoneNumber

        tiresBought

        tiresBoughtNotes

        tiresStored

        tiresStoredNotes

        startTime

        endTime

        serviceBox

        notes

    }

}`,

/** Appointment list */

Appointments = gql`query Appointments ( 
    
        $transportType: String! 

        $year: Int!

        $month: Int!

        $day: Int!
        
    ) { appointmentsForDay ( 
        
        transportType: $transportType

        year: $year

        month: $month

        day: $day
        
    ) {

        day
        
        endHour
        
        endMinute
        
        fullStartTime
        
        fullEndTime
        
        month
        
        serviceBox1Busy
        
        serviceBox2Busy
        
        serviceBoxTranslation
        
        startHour
        
        startMinute
        
        status
        
        statusTranslation
        
        year

    } 

}`;

/** Export queries */

export { 
    
    Appointments, 
    
    Appointment, 
    
    AppointmentExtended 

}