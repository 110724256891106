/** @import modules */

import { useTranslation }               from "react-i18next";

import { useParams, useNavigate }       from "react-router-dom";

/** @import hooks */

import { BatteriesNavigator }           from "../../../../../Hooks/BatteriesNavigator";

/** @import icons */

import { BsFillCaretDownFill }          from "react-icons/bs";

/**
 * 
 * @param { attributes } param0 
 * 
 * @returns capacities filter component
 * 
 */

const Capacities = ( { attributes } ) => { const 
    
    { t } = useTranslation (),

    navigate            = useNavigate       (),

    { parameters }      = useParams         (),

    Filter = ( event ) => { const 

        object = { f : 12, l: null, a: null, b: null, ca: ( event.target.value === "default" ) ? null : event.target.value };

        return navigate (  
            
            `/shop/products/batteries/${ 
            
                new URLSearchParams ( BatteriesNavigator ( parameters, object ) ).toString () }` );

    };

    /** @returns component */

    return ( <div className="content capacities">

        <label className="select shadow-select">

            <select onChange={ e => Filter ( e ) } defaultValue={ new URLSearchParams ( parameters ).get ( "ca" ) || "default" }>

                <option value="default">

                    { t ( "select_option" ) }

                </option>

                { attributes.map ( ( option, key ) => <option 
                    
                    key={ key } 
                    
                    value={ option.value }>

                        { option.text }

                </option> ) }

            </select>

            <span>
                
                <BsFillCaretDownFill />
                
            </span>

        </label>

    </div> );

}

/** Export component */
 
export default Capacities;