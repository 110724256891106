/** @import modules */

import { useTranslation } from "react-i18next";

const Fields = ( { parameters } ) => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="fields">

        { parameters.map ( ( field, index ) => ( field.type === "select" ) ? 
            
            <div className="form-group" key={ index }>

                <label htmlFor={ field.name }>

                    { t ( field.text ) }

                </label>

                <select name={ field.name } id={ field.id } onChange={ e => field.action.set ( e.target.value ) }>

                    { field.options.map ( ( option, index ) => <option value={ option.value } key={ index }>

                        { option.text }

                    </option> ) }

                </select>

            </div>

        : <div className="form-group" key={ index }>

            <label htmlFor={ field.name }>

                { t ( field.text ) }

            </label>

            <input 
            
                type={ field.type } 
            
                name={ field.name } 
            
                id={ field.id } 
            
                onChange={ e => field.action.set ( e.target.value ) } />

        </div> ) }

    </div> );

}

/** @exports Fields */

export default Fields;