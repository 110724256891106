/** Import modules */

import { useQuery }         from "@apollo/client";

import cookies              from "js-cookie";

/** Import queries */

import { HomeArticles }     from "../Queries/Blog";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Blog posts collection model
 * 
 */

const Articles = () => { const 

    { loading, data } = useQuery ( HomeArticles, { variables: { first: 3, language: cookies.get ( 'i18next' ) || "lv" } } );

    /** Return model */

    return { loading, data }

}

/** Export model */

export default Articles;