/** Import assets */

import "../../../../Styles/Products.css";

/**
 * 
 * @param { title, children } param0 
 * 
 * @returns Tyres catalog layout component
 * 
 */

const SingleRimLayout = ( { data, children } ) => {

    /** Return component */

    return ( <div className="content single-product single-rim">

        <div className="title">
            
            <h2>

                { `${ data.manufacturer.name.toUpperCase () } - ${ data.model } - ` }

                <span>

                    { `${ data.width } | R${ data.diameter }` }

                </span>

            </h2>
            
        </div>
    
        <div className="layout">

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default SingleRimLayout;