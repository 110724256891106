import { gql } from '@apollo/client';

const 

CREATE_ORDER = gql`mutation CreateOrder ( 
    
    $products: [OrderProductInput!]!, 
    
    $assembly: Boolean!,
    
    $mobilePhoneNr: String!,

    $deliveryMethod: String!,

    $paymentMethod: String!,

    $city: String,

    $address: String,

    $email: String!,

    $postCode: String,

    $firstName: String!,

    $lastName: String!,

    $pvnRegNr: String,

    $companyName: String

    ) {

    createOrder ( input: { products: $products, orderInfo: {

        assembly: $assembly,

        mobilePhoneNr: $mobilePhoneNr,

        deliveryMethod: $deliveryMethod,

        paymentMethod: $paymentMethod,

        city: $city,

        address: $address,

        email: $email,

        postCode: $postCode,

        firstName: $firstName,

        lastName: $lastName,

        pvnRegNr: $pvnRegNr,

        companyName: $companyName


    } } ) {

        order { 
            
            price 

            status

            deliveryMethod
        
        }

    }

}`;

/** @export mutations */

export { CREATE_ORDER }