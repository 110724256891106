/** @import modules */

import { useQuery }         from "@apollo/client";

import { useContext }       from "react";

/** @import contexts */

import { CartContext }      from "../Contexts/CartContext";

/** @import queries */

import { CartSummary }      from "../Queries/Orders";

/**
 * 
 * @param { params } attr 
 * 
 * @returns Cart items collection model
 * 
 */

const OrderSummary = () => { const

    { cart } = useContext ( CartContext ),

    { loading, data, refetch } = useQuery ( CartSummary, { variables: { productParams: cart || [] } } )

    /** Return model */

    return { loading, data, refetch }

}

/** Export model */

export default OrderSummary;