/** @import models */

import { useTranslation } from "react-i18next";

import ContactsModel from "../../../../Models/Contacts";

/** @import components */

import ComponentLoader from "../../../Layout/Loaders/Loader";

const Bank = () => { const { t } = useTranslation (),

    { data, loading } = ContactsModel (),

    Requisites = () => {

        if ( loading ) return <ComponentLoader />

        const req = [

            { name: "company_name", value: data.contactInfo.requisites.company_name },

            { name: "reg_no", value: data.contactInfo.requisites.reg_no },

            { name: "bank", value: data.contactInfo.requisites.bank },

            { name: "bank_account_number", value: data.contactInfo.requisites.bank_account_number }

        ];

        return <div className="table">

            { req.map ( ( req, index ) => <div className="row" key={ index }>

                <div className="text items">

                    { t ( req.name ) }

                </div>

                <div className="value">

                    { req.value }

                </div>

            </div> ) }
            
        </div>

    };

    /** @returns */

    return ( <div className="bank">

        <Requisites />

    </div> );

}

/** @exports Bank */
 
export default Bank;