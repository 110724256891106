/** Import hooks */

import { Cleaner } from "../Misc/ObjectCleaner";

const

/** @returns URL parameters */

TyresNavigator = ( params, update ) => { const parameters = new URLSearchParams ( params ),

    /** Register attributes */

    attributes = {

        f       : parseInt      ( parameters.get ( "f" ) ),

        l       : parseInt      ( parameters.get ( "l" ) ),

        b       : parameters.get ( "b" ),

        a       : parameters.get ( "a" ),

        w       : parseInt      ( parameters.get ( "w" ) ),

        h       : parseFloat    ( parameters.get ( "h" ) ),

        d       : parseFloat    ( parameters.get ( "d" ) ),

        s       : parameters.get ( "s" ),

        m       : parameters.get ( "m" ),

        sp      : parameters.get ( "sp" ),

        li      : parameters.get ( "li" ),

        ni      : parameters.get ( "ni" ),

        sr      : parameters.get ( "sr" ),

        fe      : parameters.get ( "fe" ),

        wg      : parameters.get ( "wg" ),

        vt      : parameters.get ( "vt" ),

        sort    : parameters.get ( "sort" )

    };

    /** Optimize, clean & return attributes */

    return Cleaner ( { ...attributes, ...update } );

},

/** @returns Query attributes based on URL parameters */

QueryAttributes = ( params ) => { const parameters = new URLSearchParams ( params );

    /** Optimize, clean & return query attributes */

    return Cleaner ( {

        first                       : parseInt      ( parameters.get ( "f" ) ),

        last                        : parseInt      ( parameters.get ( "l" ) ),

        before                      : parameters.get ( "b" ),

        after                       : parameters.get ( "a" ),

        width                       : parseInt      ( parameters.get ( "w" ) ),

        height                      : parseFloat    ( parameters.get ( "h" ) ),

        diameter                    : parseFloat    ( parameters.get ( "d" ) ),

        season                      : parameters.get ( "s" ) ? parameters.get ( "s" ).split ( "," ) : [],

        manufacturer                : parameters.get ( "m" ) ? parameters.get ( "m" ).split ( "," ) : [],

        specifications              : parameters.get ( "sp" ),

        loadIndex                   : parameters.get ( "li" ),

        noiseIndex                  : parameters.get ( "ni" ),

        speedRating                 : parameters.get ( "sr" ),

        vehicleType                 : parameters.get ( "vt" ),

        fuelEfficiency              : parameters.get ( "fe" ) ? parameters.get ( "fe" ).split ( "," ) : [],

        wetBreakingEfficiency       : parameters.get ( "wg" ) ? parameters.get ( "wg" ).split ( "," ) : [],

        sortOrder                   : parameters.get ( "sort" )

    } );

};

/** Export hooks */
 
export { TyresNavigator, QueryAttributes };