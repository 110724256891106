/** @import modules */

import { useEffect }        from "react";

import { useLocation }      from "react-router-dom";

const 

/** @returns */

ScrollController = () => { const

    { pathname } = useLocation ();

    useEffect ( () => window.scrollTo ( 0, 0 ), [ pathname ] );

    return null;

};

/** @exports SctrollController */

export default ScrollController;