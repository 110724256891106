/** @import modules */

import { Link }                 from "react-router-dom";

/** @import misc */

import { BatteryPermalinks }    from "../../../../../Misc/Permalinks";

/** @import components */

import Properties               from "../Properties";

/** @import assets */

import NoProduct                from "../../../../../Assets/no-batteries.jpg";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog grid item component
 * 
 */

const GridItem = ( { product } ) => { const permalink = BatteryPermalinks ( product );

    /** Return component */

    return ( <div className="item">

        <Link to={ permalink }>

            <div className="thumbnail batteries">

                { ( ! product.awsImageUrl ) ? <img src={ NoProduct } alt={ product.model } /> : <img src={ product.awsImageUrl } alt={ product.model } /> }

            </div>

            <div className="name">
                
                <div className="model">

                    <span>{ product.manufacturer.name }</span>

                    { product.model }

                </div>
                
            </div>

            <Properties product={ product } />

            <div className="price">
                
                &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }
                
            </div>

        </Link>

    </div> );

}

/** Export component */
 
export default GridItem;