/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import ContentsLayout from "../../Components/Contents/Layout";

import cookies from "js-cookie";

import { useQuery } from "@apollo/client";

import { PAGES } from "../../Queries/Pages";

import parse                    from "html-react-parser";

/**
 * 
 * @returns Gallery page
 * 
 */

const Gallery = () => { const { t } = useTranslation ();

const { data, loading } = useQuery ( PAGES, { variables: {

    language: cookies.get ( 'i18next' ) || "lv",

    pageType: "gallery"

} } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "gallery" ), URI: "/content/gallery" }

        ] } />

<ContentsLayout title={ t ( "gallery" ) }>

{ loading ? "..." : typeof data != "undefined" && Object.keys(data.frontendPages.edges).length && parse ( data.frontendPages.edges[0].node.content ) }

</ContentsLayout>
        
    </div> );

}

/** Export component */
 
export default Gallery;