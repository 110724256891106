/** @import modules */

import { useContext }           from "react";

/** @import hooks */

import { 
    
    basicValidation, 
    
    deliveryValidation, 
    
    paymentValidation }         from "../../../Hooks/Checkout/Validation";

import { ButtonLabel }          from "../../../Hooks/Checkout/Button";

/** @import contexts */

import { CheckoutContext }      from "../../../Contexts/Checkout";

/**
 * 
 * @returns place an order component
 * 
 */

const Submit = ( { step, errors, order } ) => { const 

    { 
        Type,

        Company, RegistrationNo, VAT,

        Name, Lastname, Email, Phone,

        Delivery,

        // Country, Town, Region, Street, StreetNo, Postal,

        Payment,

        Policy 
    
    } = useContext ( CheckoutContext ),

    PlaceOrder = () => {

        switch ( step.view ) {

            /** @returns basic information validation */

            case "basic" : const validate_customer = basicValidation ( { 
                
                    type        : Type, 
                    
                    name        : Name, 
                    
                    lastname    : Lastname, 
                    
                    email       : Email, 
                    
                    phone       : Phone,

                    company     : Company, 
                    
                    regno       : RegistrationNo, 
                    
                    vat         : VAT
            
                }, errors );
            
                if ( validate_customer ) step.set ( "delivery" ); break;

            /** @returns delivery information validation */

            case "delivery" : const validate_delivery = deliveryValidation ( { 
                
                    delivery_method: Delivery 
                
                }, errors );

                if ( validate_delivery ) step.set ( "payments" ); break;

            /** @returns payment information validation */

            case "payments" : const validate_payments = paymentValidation ( { 
                
                    method: Payment,
                    
                    policy: Policy
                
                }, errors );
            
                if ( Payment.view === "terminal" ) {

                    if ( validate_payments ) {

                        order ( { variables: {

                            assembly: false,

                            mobilePhoneNr: Phone.view,

                            deliveryMethod: Delivery.view,

                            paymentMethod: Payment.view,

                            email: Email.view,

                            firstName: Name.view,

                            lastName: Lastname.view,

                            pvnRegNr: RegistrationNo.view,

                            companyName: Company.view,

                            products: [

                                { productId: 10533, productCount: 1 },

                                { productId: 10536, productCount: 2 },

                                { productId: 29357, productCount: 1 }

                            ]
                
                        } } );

                        return step.set ( "terminal" );

                    }

                }

                if ( validate_payments ) console.log ( "Place regular order" ); break;

            /** @returns terminal information validation */

            case "terminal" : console.log ( "Execute payment" ); break;

            /** @returns default behavior */

            default: return;

        }

    }

    /** @returns */

    return ( <button 
    
        className="submit_checkout" 
    
        onClick={ () => PlaceOrder () }>

            { ButtonLabel ( step.view, Payment.view ) }

    </button> );

}
 
/** @exports Submit */

export default Submit;