const Banner = () => {

    /** @return component */

    return ( <div className="banner">

        Banner Placeholder

    </div> );

}

/** @export Banner */
 
export default Banner;