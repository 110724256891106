/** @import modules */

import { useEffect, useState } from "react";

/** @import icons */

import { BsCloudRainFill } from "react-icons/bs";

import { MdLocalGasStation } from "react-icons/md";

import { GiVibratingBall } from "react-icons/gi";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Single product properties component
 * 
 */

const Properties = ( { product } ) => { const 

    [ noise, setNoise ] = useState ( 0 );

    useEffect ( () => {

        if ( ! product.noiseIndex ) {

            setNoise ( false );
    
        } else if ( product.noiseIndex === 0 ) {
    
            setNoise ( false );
    
        } else {
    
            setNoise ( product.noiseIndex );
    
        }

    }, [ noise, product.noiseIndex ] );

    /** Return component */

    return ( <div className="properties">
        
        { product.wetBreakingEfficiency && 
        
            <>

                <div className="icon"><BsCloudRainFill /></div>

                <div className={ `value property-color-${ product.wetBreakingEfficiency }` }>{ product.wetBreakingEfficiency.toUpperCase () }</div>

            </>

        }

        { product.fuelEfficiency && 
            
            <>

                <div className="icon"><MdLocalGasStation /></div>

                <div className={ `value property-color-${ product.fuelEfficiency.toLowerCase () }` }>{ product.fuelEfficiency.toUpperCase () }</div>

            </>

        }

        { noise && 
            
            <>

                <div className="icon"><GiVibratingBall /></div>

                <div className="value-numeric">

                    { noise } dB

                </div>

            </>

        }
        
    </div> );

}

/** Export component */
 
export default Properties;