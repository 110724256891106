/** Import modules */

import { Link }             from "react-router-dom";

import { useTranslation }   from "react-i18next";

/** Import icons */

import { GiCarWheel }       from "react-icons/gi";

/**
 * 
 * @param {action} param0 
 * 
 * @returns Mobile navigation sale component
 * 
 */

const MobileNavSale = ( { action } ) => { const { t } = useTranslation ();

    /** Return component */

    return (<div className="sale">

        <Link to="/shop/products/sale" onClick={ () => action.set ( false ) }>

            { t ( "sale" ) }

            <span>

                <GiCarWheel />

            </span>

        </Link>

    </div>);
    
}

/** Export component */
 
export default MobileNavSale;