/** @import modules */

import { useTranslation }           from "react-i18next";

import { useState, useContext }     from "react";

/** @import hooks */

import { qtySum }                   from "../../../../../Hooks/QtySum";

/** @import contexts */

import { CartContext }              from "../../../../../Contexts/CartContext";

/** @import icons */

import { FaShoppingCart }           from "react-icons/fa";

import { 
    
    AiOutlinePlus, 
    
    AiOutlineMinus }                from "react-icons/ai";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Add to cart component
 * 
 */

const Cart = ( { product, notifications } ) => { const { t } = useTranslation (),

    { cart, setCart } = useContext ( CartContext ),

    maxQty = qtySum ( product.productVendors ),

    [ quantity, setQuantity ] = useState ( ( maxQty === 0 ) ? 0 : 1 ),

    /** @returns Product added to cart */

    addToCart = ( id, qty ) => {

        if ( cart.find ( x => x.id === id ) ) {

            cart.filter ( x => x.id === id ).map ( x => {

                if ( parseInt ( x.qty ) + qty > parseInt ( maxQty ) ) { notifications.set ( [ { type: "error", msg: "add_cart_err" } ] ); return null };

                x.qty = parseInt ( x.qty ) + qty;

                notifications.set ( [ { type: "success", msg: "add_cart_success" } ] );

                return null;

            } );

            return setCart ( [...cart ] );

        }

        // Validate Quantity

        notifications.set ( [ { type: "success", msg: "add_cart_success" } ] );

        setCart ( [...cart, { id, qty : parseInt( qty ) } ] );

    },

    /** @returns Limit max and min values of quantity input */

    QtyController = event => {

        if ( parseInt ( event.target.value ) > maxQty ) return null;

        if ( parseInt ( event.target.value ) < 1 ) return null;

        setQuantity ( event.target.value );

    },

    /** 
     * 
     * @param { action } param
     * 
     * @returns Product quantity for adding to cart */

    productQuantity = action => {

        switch ( action ) {

            case "add" : 

                if ( quantity < maxQty ) return setQuantity ( quantity + 1 );
            
                break;

            case "remove" : 

                if ( quantity > 1 ) return setQuantity ( quantity - 1 );
            
                break;

            default : break;

        }

    };

    /** @returns component */

    return ( <div className="add-to-cart">

        <div className="label">

            { t ( "qty" ) }

        </div>

        <div className="quantity-content">

            <div className="down">

                <button onClick={ () => productQuantity ( "remove" ) }>

                    <AiOutlineMinus />

                </button>

            </div>

            <div className="quantity">

                <input type="number" value={ quantity } onChange={ e => QtyController ( e ) } disabled={ ! maxQty } />

            </div>

            <div className="up">

                <button onClick={ () => productQuantity ( "add" ) }>

                    <AiOutlinePlus />
                    
                </button>

            </div>

        </div>

        <div className="add">

            <button onClick={ () => addToCart ( product.id, quantity ) } disabled={ ! maxQty }>
                
                { t ( "add_to_cart" ) }

                <span>

                    <FaShoppingCart />

                </span>
                
            </button>

        </div>

    </div> );

}

/** Export component */
 
export default Cart;