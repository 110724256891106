/** Import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { useState }             from "react";

/** @import hooks */

import { qtySum }               from "../../../Hooks/QtySum";

/** Import models */

import Product                  from "../../../Models/SingleTyre";

/** Import components */

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

import SingleTyreLayout         from "../../../Components/Products/Tyres/Single/Layout";

import Description              from "../../../Components/Products/Tyres/Single/Description";

import SingleProductData        from "../../../Components/Products/Tyres/Single/Product";

import Price                    from "../../../Components/Products/Tyres/Single/Data/Price";

import Stock                    from "../../../Components/Products/Tyres/Single/Data/Stock";

import Features                 from "../../../Components/Products/Tyres/Single/Data/Features";

import Properties               from "../../../Components/Products/Tyres/Single/Data/Properties";

import Cart                     from "../../../Components/Products/Tyres/Single/Data/Cart";

import Compare                  from "../../../Components/Products/Tyres/Single/Data/Compare";

import Title                    from "../../../Components/Products/Tyres/Single/Data/Title";

import Specifications           from "../../../Components/Products/Layout/Single/Specifications";

import ActionNotification       from "../../../Components/Products/Tyres/Single/Data/Notifications";

import Delivery                 from "../../../Components/Products/Shared/Single/Delivery";

/**
 * 
 * @returns Single tyre page
 * 
 */

const SingleTyre = () => {  const 
    
    { t }                   = useTranslation    (),

    productId               = useParams         ().productId,

    { loading, data }       = Product           ( productId ),

    [ 
        
        cartNotification, 
        
        setCartNotification ] = useState ( [] ),

    [ 
    
        compareNotification, 
        
        setCompareNotification ] = useState ( [] ),

    /** @returns Loaded product data and components */

    LoadProduct = () => {

        if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node;

        console.log( product );

        return <SingleTyreLayout data={ product }>

            <SingleProductData data={ { 
                
                image : product.awsImageUrl, 
                
                model : `${ product.manufacturer.name.toUpperCase () } ${ product.model }` } }>

                    <Title product={ product } />

                    <Price price={ parseFloat ( product.productVendors[0].price ).toFixed ( 2 ) } />

                    <Stock quantity={ qtySum ( product.productVendors ) } />

                    <Properties product={ product } />

                    <Features product={ product } />

                    <Delivery product={ product } />

                    { cartNotification.length > 0 && 

                        <ActionNotification data={ cartNotification } /> }

                    <Cart product={ product } notifications={ { view: cartNotification, set: setCartNotification } } />

                    { compareNotification.length > 0 && 

                        <ActionNotification data={ compareNotification } /> }

                    <Compare productId={ product.id } notifications={ { view: compareNotification, set: setCompareNotification } } />

            </SingleProductData>

            <Specifications data={ [

                { name: t ( "manufacturer" ), value: product.manufacturer.name, style: "uppercase" },

                { name: t ( "model" ), value: product.model, style: "regular" },
                
                { name: t ( "vehicle_type" ), value: product.vehicleType, style: "regular" },

                { name: t ( "width" ), value: product.width, style: "regular" },

                { name: t ( "height" ), value: product.height, style: "regular" },

                { name: t ( "diameter" ), value: `R${ product.diameter }`, style: "regular" },

                { name: t ( "season" ), value: t ( product.season ), style: "regular" },

                { name: t ( "speed_rating" ), value: product.speedRating, style: "uppercase" },

                { name: t ( "load_index" ), value: product.loadIndex, style: "regular" },

                { name: t ( "noise_index" ), value: product.noiseIndex, style: "regular" },

                { name: t ( "fuel_efficiency" ), value: product.fuelEfficiency, style: "uppercase" },

                { name: t ( "wet_breaking" ), value: product.wetBreakingEfficiency, style: "uppercase" },

                { name: "OE", value: product.specifications.join ( ", " ) }

            ] } />

            <Description description={ product.description } />

        </SingleTyreLayout>

    };

    /** @return component */

    return ( <div className="container max-content-width">

        { ! loading && 

        <Breadcrumbs collection={ [

            { name: t ( "tyres" ), URI: "/shop/products/tyres" },

            { name: data.product.edges[ 0 ].node.manufacturer.name.toUpperCase (), URI: `/shop/products/tyres/${

                new URLSearchParams ( { f: 12, m: data.product.edges[ 0 ].node.manufacturer.name } ).toString ()

            }` },

            { name: data.product.edges[ 0 ].node.model, URI: "/shop/products/tyres" },

        ] } type="basic" /> }

        <LoadProduct />
            
    </div> );

}

/** @export component */
 
export default SingleTyre;