/** @import icons */

import { FaDotCircle } from "react-icons/fa";

const Radios = ( { parameters, name, action } ) => {

    /** @returns */

    return ( <div className="radios">

        { parameters.map ( ( radio, index ) => <div className="radio" key={ index }>

            <label htmlFor={ radio.id }>

                <input 
                
                    type="radio" 
                
                    id={ radio.id } 
                
                    name={ name } 
                
                    value={ radio.value } 

                    checked={ ( action.view === radio.value ) ? true : false }
                
                    onChange={ e => action.set ( e.target.value ) } />

                <span className="icon">

                    <span className="checked">

                        <FaDotCircle />

                    </span>

                </span>

                <span className="name">

                    { radio.label }

                </span>

            </label>

        </div> ) }

    </div> );

}

/** @exports Radios */

export default Radios;