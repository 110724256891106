/** Import modules */

import { useTranslation }   from "react-i18next";

/** Import components */

import Breadcrumbs          from "../../Components/Layout/Header/Breadcrumbs";

import ContentsLayout       from "../../Components/Contents/Layout";

/**
 * 
 * @returns Delivery page
 * 
 */

const Delivery = () => { const { t } = useTranslation ();

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "delivery_information" ), URI: "/content/delivery" }

        ] } />

        <ContentsLayout title={ t ( "delivery_information" ) }>

            <p>

                SIA Tiremarket mēs saprotam, ka mūsu klientiem ir svarīga savlaicīga un precīza piegāde.

            </p>

            <p>

                Papildus mūsu piegādes iespējām klientiem ir arī iespēja izņemt pasūtījumu kādā no mūsu fiziskā veikala vietām. Lai izvēlētos šo opciju, pie pirkuma noformēšanas izvēlieties "Saņemt veikalā" un izvēlieties vietu, no kuras vēlaties saņemt pasūtījumu. Kad pasūtījums būs gatavs, mēs nosūtīsim jums paziņojumu, ka tas ir pieejams izņemšanai veikala darba laikā.

            </p>

            <p>

                Standarta piegādes laiks pasūtījumiem ir 3–5 darbadienas. Starptautiskiem pasūtījumiem piegādes laiki ir mainīgi atkarībā no galamērķa valsts un tiks norādīti pirkuma brīdī.

            </p>

            <p>

                Gadījumā, ja prece nav noliktavā, mēs nekavējoties sazināsimies ar jums, lai informētu par aizkavēšanos un norādītu paredzamo piegādes datumu.

            </p>

            <p>

                Ja jums ir kādi jautājumi vai neskaidrības par pasūtījumu, lūdzu, sazinieties ar mūsu klientu apkalpošanas komandu pa tālruni +371 26 60 62 62 vai rakstiet mums e-pastā info@tiremarket.eu. Patīkamu iepirkšanos.

            </p>

        </ContentsLayout>
        
    </div> );

}

/** Export component */
 
export default Delivery;