/** @import modules */

import { useTranslation }   from "react-i18next";

import Cash         from "./Cash";

import Bank         from "./Bank";

import Terminal     from "./Terminal";

/**
 * 
 * @param { method }
 *  
 * @returns payment method selector component
 * 
 */

const PaymentMethods = ( { method } ) => { const { t } = useTranslation (),

    Methods = ( { payment } ) => {

        switch ( payment ) {

            case "cash"         : return <Cash />

            case "bank"         : return <Bank />

            case "terminal"     : return <Terminal />

            default             : return <div className="select-option-placeholder">

                { t ( "select_payment" ) }

            </div>

        }

    }

    /** @returns */

    return ( <div className="payment-methods">

        <Methods 
        
            payment={ method } />

    </div> );

}

/** @exports PaymentMethods */
 
export default PaymentMethods;