const Omniva = () => {

    /** @returns */

    return ( <div className="omniva">

        <div className="select-option-placeholder">

            Omniva API Placeholder

        </div>

    </div> );

}

/** @exports Omniva */
 
export default Omniva;