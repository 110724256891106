/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import Size                     from "../Components/Products/Tyres/Catalog/Filters/Size";

import Seasons                  from "../Components/Products/Tyres/Catalog/Filters/Seasons";

import Manufactures             from "../Components/Products/Tyres/Catalog/Filters/Manufactures";

import LoadIndex                from "../Components/Products/Tyres/Catalog/Filters/LoadIndex";

import NoiseIndex               from "../Components/Products/Tyres/Catalog/Filters/NoiseIndex";

import OEM                      from "../Components/Products/Tyres/Catalog/Filters/OEM";

import SpeedRating              from "../Components/Products/Tyres/Catalog/Filters/SpeedRating";

import FuelEfficiency           from "../Components/Products/Tyres/Catalog/Filters/Fuel";

import VehicleType              from "../Components/Products/Tyres/Catalog/Filters/VehicleType";

import Grip                     from "../Components/Products/Tyres/Catalog/Filters/Grip";

const 

/** @return filter widgets */

FiltersCollection = ( data ) => { const { t } = useTranslation (),

    { 
        
        heights, 
        
        widths, 
        
        diameters, 
        
        seasons, 
        
        manufacturers, 
        
        specifications, 

        speed_ratings,
        
        load_indexes,

        wet_grip,

        noise_indexes,

        fuel_efficiencies,

        vehicle_types

    
    } = data.searchAttributes.tires[0],

    Manufacturers = [ ...manufacturers ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ),

    WetGrip = [ ...wet_grip ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ).filter ( el => el.value != null ),

    FuelEff = [ ...fuel_efficiencies ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ).filter ( el => el.value != null ),

    LoadIndexes = [ ...load_indexes ].sort ( ( a, b ) => parseInt ( a.value ) - parseInt ( b.value ) ),
    
    NoiseIndexes = [ ...noise_indexes ].sort ( ( a, b ) => parseInt ( a.value ) - parseInt ( b.value ) );

    return [

        { 
            
            title: t ( "vehicle_type" ), 
        
            content: <VehicleType attributes={ vehicle_types } />, 
        
            toggle: true },

        { 
            
            title: t ( "tyre_size" ), 
        
            content: <Size attributes={ { widths, heights, diameters } } />, 
        
            toggle: false },

        { 
            
            title: t ( "season" ), 
        
            content: <Seasons attributes={ seasons } />, 
        
            toggle: true },

        { 
            
            title: t ( "manufacturer" ), 
        
            content: <Manufactures attributes={ Manufacturers } />, 
        
            toggle: true },

        { 
    
            title: t ( "speed_rating" ), 
        
            content: <SpeedRating attributes={ speed_ratings } />, 
        
            toggle: true },

        { 
    
            title: t ( "load_index" ), 
        
            content: <LoadIndex attributes={ LoadIndexes } />, 
        
            toggle: true },

        { 

            title: t ( "noise_index" ), 
        
            content: <NoiseIndex attributes={ NoiseIndexes } />, 
        
            toggle: true },

        { 

            title: t ( "fuel_efficiency" ), 
        
            content: <FuelEfficiency attributes={ FuelEff } />, 
        
            toggle: true },

        { 

            title: t ( "wet_breaking" ), 
        
            content: <Grip attributes={ WetGrip } />, 
        
            toggle: true },

        { 
        
            title: t ( "oem" ), 
        
            content: <OEM attributes={ specifications } />, 
        
            toggle: true }

    ];

}, 

/** @return size filter data */

SizeFilters = ( attributes ) => { const { t } = useTranslation (),

    { heights, widths, diameters } = attributes,

    Width       = [ ...widths    ].sort( ( a, b ) => parseInt      ( a.value ) - parseInt      ( b.value ) ),

    Height      = [ ...heights   ].sort( ( a, b ) => parseFloat    ( a.value ) - parseFloat    ( b.value ) ),

    Diameter    = [ ...diameters ].sort( ( a, b ) => parseFloat    ( a.value ) - parseFloat    ( b.value ) );

    return [

        { 
            
            name        : t ( "width" ), 
        
            tooltip     : "Width tooltip", 
        
            options     : Width, 
        
            type        : "INT", 
        
            id          : "w" },

        { 
            
            name        : t ( "height" ), 
        
            tooltip     : "Height tooltip", 
        
            options     : Height, 
        
            type        : "FLOAT", 
        
            id          : "h" },

        { 
            
            name        : t ( "diameter" ), 
        
            tooltip     : "Diameter tooltip", 
        
            options     : Diameter, 
        
            type        : "FLOAT", 
        
            id          : "d" }

    ];

};

/** @export datasets */

export { 
    
    FiltersCollection, 
    
    SizeFilters

}