/** @import modules */

import { useContext }           from "react";

import { useTranslation }       from "react-i18next";

/** @import contexts */

import { MontageContext }       from "../../../Contexts/MontageContext";

/** @import misc */

import { TimeConverter }        from "../../../Misc/Montage/TimeConverter";

import { scrollElement }        from "../../../Misc/ScrollElement";

/** @import components */

import ComponentLoader          from "../../Layout/Loaders/Loader";

/** @import models */

import { AppointmentsModel }    from "../../../Models/Appointments";

/**
 * 
 * @returns montage appointment time selector component
 * 
 */

const Datetime = () => { const { t } = useTranslation (),

    { 
        
        SHD, 
        
        SMD, 
        
        EHD, 
        
        EMD, 
        
        TimeState, 
        
        Type, 
        
        SelectedDate,

        DateState
    
    } = useContext ( MontageContext ),

    { loading, data } = AppointmentsModel ( {

        year    : SelectedDate.view.getFullYear () || new Date ().getFullYear (),

        day     : SelectedDate.view.getDate () || new Date ().getDate (),

        month   : SelectedDate.view.getMonth () + 1 || new Date ().getMonth () + 1,

        type    : Type.view

    } ),

    /** @update hook action */

    Time = ( time, id ) => {

        TimeState.set ( id );

        SHD.set ( time.startHour     );

        SMD.set ( time.startMinute   );

        EHD.set ( time.endHour       );

        EMD.set ( time.endMinute     );

    },

    Appointments = () => {

        if ( loading ) return <ComponentLoader />

        return <div className="datetime">

            { DateState.view &&

                data.appointmentsForDay.map ( ( item, index ) => 
                
                    <div className="card" key={ index }>
                    
                        <input 
                        
                            type="radio" 
                            
                            id={ index + 1 } 
                            
                            name="time" 
                            
                            disabled={ ( item.status === "active" ) ? false : true } 
                            
                            defaultChecked={ ( index + 1 === TimeState.view ) ? true : false } onClick={ () => { Time (item, index + 1 ); return scrollElement ( ".montage-form" ); } } />
                        
                        <label htmlFor={ index + 1 } className={ item.status }>

                            <div className="time">

                                { item.status === "active" ? TimeConverter ( item ) : t ( "time_expired" ) }

                            </div>

                            { item.status === "active" && <div className="box">

                                <span className={ item.serviceBox1Busy ? "unavailable" : "available" }></span>

                                { t ( "bokss" ) } 1

                            </div> }

                            { item.status === "active" && <div className="box">

                                <span className={ item.serviceBox2Busy ? "unavailable" : "available" }></span>

                                { t ( "bokss" ) } 2

                            </div> }

                        </label>

                    </div> 
                
                )

            }

        </div>

    }

    /** @return component */

    return ( <Appointments /> );

}

/** @export Datetime */
 
export default Datetime;