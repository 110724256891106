/** @import */

import { Link, useNavigate }                from "react-router-dom";

import { Fragment, useContext, useRef, useState }     from "react";

import { useTranslation }                   from "react-i18next";

import { useMutation }                      from '@apollo/client';

import { MontageContext }                   from "../../../Contexts/MontageContext";

import { ADD_APPOINTMENT }                  from "../../../Mutations/Appointment";

import { CarFields, PersonFields }          from "../../../Datasets/Montage";

import { ErrorsCheck }                      from "../../../Misc/Montage/ErrorsController";

import { scrollElement }                    from "../../../Misc/ScrollElement";

import mutationArgs                         from "../../../Misc/Montage/MutationArgs";

import ErrorsController                     from "./Form/Errors";

import Placeholder                          from "./Form/Placeholder";

import MontageFieldGroup                    from "./Form/FieldGroup";

import InputController                      from "./Form/Input";

import TextareaController                   from "./Form/Textarea";

import CheckboxController                   from "./Form/Checkbox";
import { CardElement } from "@stripe/react-stripe-js";

/**
 * 
 * @returns appointment submit form
 * 
 */

const Form = () => { const { 
        
        Number, Brand, Model, 
        
        Name, Phone, Email, 
        
        TiresStoredNotes, TiresBoughtNotes, Notes, 
        
        TiresStored, TiresBought, 
        
        SelectedDate, SHD, SMD, EHD, EMD, TimeState, 
        
        Type, Payment
    
    } = useContext ( MontageContext ),

    { t } = useTranslation (),

    navigate = useNavigate (),

    [ errors, setErrors ] = useState ( [] ),

    submitButton = useRef ( null ),

    Datetime = SelectedDate.view || new Date ( "" ),

    car_data = CarFields ( { Number, Brand, Model } ),

    person_data = PersonFields ( { Name, Phone, Email } ),

    [ CreateAppointment ] = useMutation ( ADD_APPOINTMENT, {

        onCompleted ( data ) {

            if ( data.createAppointment.activerecordErrors.length > 0 ) {

                // console.log ( "Errors found", data.createAppointment.activerecordErrors );

                setErrors ( [ { err: "Back-end error" } ] );

                document.querySelectorAll ( ".montage-form input" ).forEach ( input => input.removeAttribute ( "disabled" ) );

                submitButton.current.removeAttribute ( "disabled" );

                submitButton.current.innerHTML = t ( "apply" );

                scrollElement ( ".montage-form" );

                return;

            }

            navigate ( `/content/montage/appointment/${ data.createAppointment.appointment.destroyUuid }/success` );

        },

        onError ( error ) { console.log ( "Error occurred", error ); }

    } ),

    SubmitAppointment = () => {

        /** reset errors */

        setErrors ( [] );

        /** validate fields */

        const Verification = ErrorsCheck ( {

            state       : { view: errors, set: setErrors },

            vehicle     : car_data,

            customer    : person_data,

            type        : Type,

            stored      : TiresStored,

            bought      : TiresBought,

            storedNotes : TiresStoredNotes,

            boughtNotes : TiresBoughtNotes

        } );

        if ( Verification.length ) { scrollElement ( ".montage-form" ); return; }

        /** reset errors */

        setErrors ( [] );

        /** create appointment */

        const inputs = document.querySelectorAll ( ".montage-form input" );

        submitButton.current.innerHTML = t ( "submitting" );

        submitButton.current.setAttribute ( "disabled", "disabled" );

        inputs.forEach ( input => input.setAttribute ( "disabled", "disabled" ) );

        CreateAppointment ( { variables: mutationArgs ( {

            type        : Type,

            date        : SelectedDate,
            
            start       : { hour : SHD, minute : SMD },

            end         : { hour : EHD, minute : EMD },

            vehicle     : car_data,

            customer    : person_data,

            stored      : TiresStored,

            bought      : TiresBought,

            storedNote  : TiresStoredNotes,

            boughtNote  : TiresBoughtNotes,

            note        : Notes

        } ) } );

    }

    /** @return component */

    return ( <div className="montage-form">

        <ErrorsController 
        
            errors={ errors } />

        { TimeState.view && <>

            <Placeholder attributes={ { 
                
                date: Datetime, 
                
                startHour: SHD.view, 
                
                startMinute: SMD.view, 
                
                endHour: EHD.view, 
                
                endMinute: EMD.view } } />

            <MontageFieldGroup 
            
                fields={ car_data } 
            
                title={ t ( "vehicle_data" ) } />

            <MontageFieldGroup 
            
                fields={ person_data } 
            
                title={ t ( "customer_data" ) } />

            <div className="title">

                { t ( "additional_montage_data" ) }

            </div>

            { Type.view !== "cooler" && <CheckboxController 
                
                id="storage" 
            
                label={ t ( "montage_items_stored" ) } 
            
                state={ TiresStored } /> }

            { TiresStored.view && <InputController 
                
                id="tires_stored_number" 

                label={ `${ t ( "storage_number" ) }:` }
            
                state={ TiresStoredNotes } /> }

            { Type.view !== "cooler" && <CheckboxController 
                
                id="bought" 
            
                label={ t ( "montage_bought_online" ) } 
            
                state={ TiresBought } /> }

            { TiresBought.view && <InputController 
                
                id="order_number" 
            
                label={ `${ t ( "order_number" ) }:` } 
            
                state={ TiresBoughtNotes } /> }

            <TextareaController 
            
                id="notes" 
            
                label={ `${ t ( "montage_notes" ) }:` }
            
                state={ Notes } /> 

            <div className="information">

                { t ( "montage_privacy_policy" ) } <Link to="/policies/privacy-policy">
                    
                    { `${ t ( "view" ) }.` }
                    
                </Link>

            </div>

            <div className="title">

                { t ( "online_banking" ) }

            </div>

            <CheckboxController 
                
                id="onlinePayment" 
            
                label={ `${ t ( "proceed_payment" ) } online (Stripe)` } 
            
                state={ Payment } />


            { Payment.view && <Fragment>

                <div class="information">{ `${ t ( "payment" ) }: 25 EUR` }</div>
            
                <CardElement /> 
                
            </Fragment> }

            <div className="submit">

                <button 
                
                    onClick={ () => SubmitAppointment () } 
                
                    ref={ submitButton }>
                    
                    { t ( "apply" ) }
                    
                </button>

            </div>

        </> }

    </div> );

}

/** @export Form */
 
export default Form;