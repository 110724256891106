/** @import models */

import { ServicesModel }                from "../../Models/Services";

import cookies                          from "js-cookie";

import { Link }                         from "react-router-dom";

/** @import components */

import ComponentLoader                  from "../Layout/Loaders/Loader";

const List = () => { const 

    { loading, data } = ServicesModel (),

    Collection = ( { object, status } ) => {

        if ( status ) return <ComponentLoader />

        let filter = object.services.edges.filter ( obj => obj.node.language === cookies.get ( 'i18next' ) && obj.node.isActive === true );

        if ( filter.length === 0 ) {

            filter = object.services.edges.filter ( obj => obj.node.language === "en" && obj.node.isActive === true );

        }

        return filter.map ( ( service, index ) => <Link to={ `/content/services/${ service.node.id }` } key={ index }>

            <img src={ service.node.image } alt={ service.node.title } />

            <div className="title">

                { service.node.title }

            </div>

        </Link> );

    };

    /** @return component */

    return ( <div className="list">

        <Collection object={ data } status={ loading } />

    </div> );

}

/** @export List */
 
export default List;