/** @import modules */

import { useTranslation }           from "react-i18next";

/** @import components */

import Breadcrumbs                  from "../../../Components/Layout/Header/Breadcrumbs";

import CheckoutLayout               from "../../../Components/Orders/Checkout/Layout";

/** @import icons */

import { AiOutlineCheckCircle }     from "react-icons/ai";

const TransactionSuccess = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "checkout" ), URI: "/orders/checkout" },

            { name: t ( "receipt_breadcrumb" ), URI: "#" }

        ] } type="basic" />

        <CheckoutLayout title={ t ( "checkout" ) }>

            <div className="receipt">

                <div className="title">
                    
                    <h2>

                        { t ( "order_thankyou" ) }

                    </h2>
                    
                </div>

                <div className="message success">

                    <div className="icon success">

                        <AiOutlineCheckCircle />

                    </div>
                    
                    <p>{ t ( "order_thankyou_message" ) }</p>
                    
                </div>

            </div>

        </CheckoutLayout>
        
    </div> );

}

/** @exports TransactionSuccess */
 
export default TransactionSuccess;