/** @import modules */

import { useContext } from "react";

import { Link } from "react-router-dom";

import { Trans } from "react-i18next";

import { CheckoutContext } from "../../../Contexts/Checkout";

import { FaCheck } from "react-icons/fa";

const Policy = () => { const

    { Policy } = useContext ( CheckoutContext );

    /** @returns */

    return ( <div className="policy-validation">

        <div className="message">
            
            <Trans i18nKey="checkout_policy_message">

                More information about our data processing policy can be found on the 
                
                <Link to="/policies/privacy-policy" target="_blank" rel="noopener noreferrer">

                    privacy policy page.    
                    
                </Link>.

            </Trans>
            
        </div>

        <label htmlFor="policy" className="checkbox">

            <input 
            
                type="checkbox" 
            
                id="policy"
            
                value="policy"
            
                onChange={ () => ( Policy.view === true ) ? Policy.set ( false ) : Policy.set ( true ) } />

            <span className="check">

                <span className="checked">

                    <FaCheck />

                </span>

            </span>

            <span className="name">

                <Trans i18nKey="checkout_policy">

                    I have read and agree to the page <Link to="/policies/terms-of-use" target="_blank" rel="noopener noreferrer">terms and conditions.</Link>

                </Trans>

            </span>

        </label>

    </div> );

}
 
/** @exports Policy */

export default Policy;