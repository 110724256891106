/** @import modules */

import { useTranslation }       from "react-i18next";

import { useContext }           from "react";

import { OrderParameters }      from "../../../Models/Checkout";

import { CheckoutContext }      from "../../../Contexts/Checkout";

import ComponentLoader          from "../../Layout/Loaders/Loader";

import Radios                   from "./Radios";

import DeliveryMethods          from "./Delivery/Methods";

const Delivery = () => { const { t } = useTranslation (),

    { Delivery } = useContext ( CheckoutContext ),

    { data, loading } = OrderParameters (),

    Options = () => {

        if ( loading ) return <ComponentLoader />

        const deliveryMethods = [];

        data.orderOptions.delivery_method.map ( ( order ) => deliveryMethods.push ( {

            id: order.value, 
            
            value: order.value, 
            
            label: t ( order.value )

        } ) );

        return <Radios 
            
            parameters={ deliveryMethods } 
        
            name="delivery_method" 
        
            action={ Delivery } />

    };

    /** @returns */

    return ( <div className="sector delivery">

        <div className="title">

            { t ( "delivery" ) }

        </div>

        <Options />

        { ! Delivery.view && <div className="select-option-placeholder">

            { t ( "select_delivery" ) }

        </div> }

        { Delivery.view && <DeliveryMethods method={ Delivery.view } /> }

    </div> );

}

/** @exports Delivery */
 
export default Delivery;