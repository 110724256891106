/** Import modules */

import { useTranslation }       from "react-i18next";

import { useParams }            from "react-router-dom";

import { useRef, useState }     from "react";

/** Import models */

import Collection               from "../../../Models/TyresCollection";

/** Import hooks */

import Display                  from "../../../Hooks/ProductDisplay";

/** Import components */

import TyresLayout              from "../../../Components/Products/Tyres/Catalog/Layout";

import Breadcrumbs              from "../../../Components/Layout/Header/Breadcrumbs";

import Products                 from "../../../Components/Products/Tyres/Catalog/Collection";

import Pagination               from "../../../Components/Products/Tyres/Catalog/Pagination";

import Actions                  from "../../../Components/Products/Tyres/Catalog/Actions";

import ComponentLoader          from "../../../Components/Layout/Loaders/Loader";

/**
 * 
 * @returns Blog page
 * 
 */

const TyresCatalog = () => { const 

    { loading, data }       = Collection ( useParams ().parameters ),
    
    { t }                   = useTranslation (),

    { layout, setLayout }   = Display (),

    filters                 = useRef ( null ),

    [ 
        
        mobileFilters, 
        
        setMobileFilters ]  = useState ( false ),

    /** Product loader */
    
    ProductCatalog = () => { if ( loading ) return <ComponentLoader />

        return <Products layout={ layout } products={ data.multiFieldProductSearch.edges } />

    };

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "tyres" ), URI: "/shop/products/tyres" }

        ] } />

        <TyresLayout title={ t ( "tyres" ) } filters={ { view: mobileFilters, set: setMobileFilters, element: filters } }>

            <Actions layout={ { view: layout, set: setLayout } } mobile={ { view: mobileFilters, set: setMobileFilters, element: filters } } />

            <ProductCatalog />

            { ! loading && 

                <Pagination pageInfo={ data.multiFieldProductSearch.pageInfo } />

            }

        </TyresLayout>
        
    </div> );

}

/** Export component */
 
export default TyresCatalog;