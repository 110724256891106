/** Import modules */

import { useNavigate }                  from "react-router-dom";

import { useTranslation }               from "react-i18next";

/** Import hooks */

import { RimsNavigator }                from "../../../../../Hooks/RimsNavigator";

/**
 * 
 * @returns Filters sanitization component
 * 
 */

const ClearFilters = () => { const 

    { t } = useTranslation (),

    navigate = useNavigate (),

    /** Clear all filters */

    Sanitize = () => {

        return navigate ( `/shop/products/rims/${ new URLSearchParams ( RimsNavigator ( "", {

            f       : 12,

            l       : null,

            b       : null,

            a       : null,

            sort    : null

        } ) ).toString () }` );

    };

    /** Return component */

    return ( <div className="clear-filters">

        <button onClick={ () => Sanitize () }>

            { t ( "clear_filters" ) }

        </button>

    </div> );

}

/** Export component */
 
export default ClearFilters;