const Location = () => {

    /** @return component */

    return ( <div className="location">

        <iframe 
        
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2177.295320517759!2d24.183425712517952!3d56.92660590086572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eed2001e7e4b13%3A0x4baf506c11a1ac68!2sKrustpils%20iela%206%2C%20Latgales%20priek%C5%A1pils%C4%93ta%2C%20R%C4%ABga%2C%20LV-1035!5e0!3m2!1sen!2slv!4v1697190403455!5m2!1sen!2slv" 
            
            title="tireshop_sia"

            loading="lazy" 
            
            referrerPolicy="no-referrer-when-downgrade">

        </iframe>

    </div> );

}
 
/** @export Location */

export default Location;