/** @import modules */

import { useTranslation } from "react-i18next";

const Pickup = () => { const { t } = useTranslation ();

    /** @returns */

    return ( <div className="pickup">

        { t ( "order_pickup" ) }

    </div> );

}

/** @exports Pickup */
 
export default Pickup;