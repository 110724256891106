/** @import modules */

import { useTranslation } from "react-i18next";

const 

ButtonLabel = ( stage, option ) => { const { t } = useTranslation ();

    if ( stage === "basic" ) return t ( "next_delivery" );

    if ( stage === "delivery" ) return t ("next_payments" );

    if ( stage === "payments" ) {

        if ( option === "terminal" ) { 
            
            return t ( "proceed_payment" ) 
        
        } else { 
            
            return t ( "place_order" ) 
        
        }

    }

};

/** @exports */

export {

    ButtonLabel

}