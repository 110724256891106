/** @import modules */

import { useTranslation }           from "react-i18next";

/** @import icons */

import { BsFillTelephoneFill }      from "react-icons/bs";

import { 
    
    FaMobileAlt, 
    
    FaEnvelope }                    from "react-icons/fa";



const ContactsInformation = ( { data } ) => { const 

    { t } = useTranslation (),

    contacts = [

        { icon: <BsFillTelephoneFill />,    name: t ( "shop_name" ),        value: "+371 27 80 5555"      },

        { icon: <FaMobileAlt />,            name: t ( "service_phone" ),    value: "+371 27 80 5555"   },

        { icon: <FaEnvelope />,             name: t ( "email" ),            value: "info@tiremarket.eu"           }

    ],

    Collection = ( { params } ) => <ul>{ 
        
        params.map ( ( info, index ) => <li key={ index }>

            <span>{ info.icon }</span>

            { `${ info.name }: ${ info.value }` }

        </li> ) }
        
    </ul>, 

    Hours = ( { params } ) => {

        return <div className="content">

            <ul>{
            
                Object.entries ( params ).map ( ( entry, index ) => <li key={ index }>

                    { `${ t ( entry[ 0 ] ) }: ${ entry[ 1 ] }` }

                </li> )

            }</ul>

        </div>

    };

    /** @return component */

    return ( <div className="office-info">

        <div className="communication">

            <div className="headline">{ t ( "contact_information" ) }</div>

            <div className="content">

                <Collection params={ contacts } />

            </div>

        </div>

        <div className="hours">

            <div className="headline">{ t ( "work_hours" ) }</div>

            <Hours params={ data.hours } />

        </div>

    </div> );

}
 
/** @export ContactsInformation */

export default ContactsInformation;