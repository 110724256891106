/** Import components */

import GridItem from "./Item/BatteryGrid";

import ListItem from "./Item/BatteryList";

/**
 * 
 * @param { layout, products } param0 
 * 
 * @returns Product catalog item component
 * 
 */

const Products = ( { layout, products } ) => { 

    /** Return component */

    return ( <div className={ `products ${ layout }` }>

        { products.map ( ( product, index ) => {

            if ( layout === "grid" ) return <GridItem 
        
                product={ product.node } 
            
                key={ index } />

            if ( layout === "list" ) return <ListItem 
        
                product={ product.node } 
            
                key={ index } />

            return null;

        } ) }

    </div> );

}

/** Export component */
 
export default Products;