/** @import modules */

import { useContext } from "react";

import { useTranslation } from "react-i18next";

import { CheckoutContext } from "../../../Contexts/Checkout";

import Radios from "./Radios";

import Fields from "./Fields";

/**
 * 
 * @returns checkout customer section
 * 
 */

const Customer = () => { const { t } = useTranslation (),

    { 
        
        Type, 
        
        Name, Lastname, 
        
        Email, Phone, 
        
        Company, RegistrationNo, VAT 
    
    } = useContext ( CheckoutContext ),

    customerType = [

        { id: "private",    value: "private", label: t ( "private_person" ) },

        { id: "legal",      value: "legal",   label: t ( "legal_person"   ) }

    ],

    companyForm = [

        { type: "text", id: "legalName", name: "legalName", text: "company_name", action: Company },

        { type: "text", id: "regNo", name: "regNo", text: "regnr", action: RegistrationNo },

        { type: "text", id: "vatNo", name: "vatNo", text: "vat", action: VAT },

    ],

    customerForm = [

        { type: "text", id: "firstName", name: "firstName", text: "name", action: Name },

        { type: "text", id: "lastName", name: "lastName", text: "lastname", action: Lastname },

        { type: "email", id: "email", name: "email", text: "email", action: Email },

        { type: "phone", id: "mobilePhoneNr", name: "mobilePhoneNr", text: "phone", action: Phone }

    ];

    /** @returns */

    return ( <div className="sector customer">

        <Radios 
        
            parameters={ customerType } 
        
            name="customertype" 
        
            action={ Type } />

        <div className="title">

            { t ( "basic_information" ) }

        </div>

        { Type.view === "legal" && <Fields parameters={ companyForm } /> }

        <Fields parameters={ customerForm } />

    </div> );

}

/** @exports Customer */
 
export default Customer;