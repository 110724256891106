/** @import modules */

import { useTranslation }           from "react-i18next";

import { useState }                 from "react";

import { useMutation }              from "@apollo/client";

/** @import mutations */

import { CREATE_ORDER }             from "../../Mutations/Checkout";

/** @import contexts */

import { CheckoutContext }          from "../../Contexts/Checkout";

/** @import hooks */

import { CheckoutHook }             from "../../Hooks/Checkout";

/** @import components */

import Breadcrumbs                  from "../../Components/Layout/Header/Breadcrumbs";

import CheckoutLayout               from "../../Components/Orders/Checkout/Layout";

import Customer                     from "../../Components/Orders/Checkout/Information";

import Delivery                     from "../../Components/Orders/Checkout/Delivery";

import Summary                      from "../../Components/Orders/Checkout/Summary";

import Payments                     from "../../Components/Orders/Checkout/Payment";

import Policy                       from "../../Components/Orders/Checkout/Policy";

import Submit                       from "../../Components/Orders/Checkout/Submit";

const Checkout = () => { const { t } = useTranslation (),

    /** @example basic, delivery, payments, terminal */

    [ step, setStep ] = useState ( "basic" ),

    [ errors, setErrors ] = useState ( [] ),

    [ CreateOrder ] = useMutation ( CREATE_ORDER, {

        onCompleted ( data ) {

            console.log ( data );

        },

        onError ( error ) { console.log ( "Error occurred", error ); }

    } );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "checkout" ), URI: "/orders/checkout" }

        ] } type="basic" />

        <CheckoutLayout title={ t ( "checkout" ) }>

            <div className="title">
                
                <h2>{ t ( "checkout" ) }</h2>
                
            </div>

            <CheckoutContext.Provider value={ CheckoutHook () }>

                { errors.length > 0 && <div className="checkout-errors">

                    { errors.map ( ( err, index ) => <div className="error" key={ index }>

                        { t ( err.msg ) }

                    </div> ) }

                </div> }

                { step === "basic" && <Customer /> }

                { step === "delivery" && <Delivery /> }

                { step === "payments" && <>

                    <Summary />

                    <Payments />

                    <Policy />

                </> }

                { step === "terminal" && <div>

                    Terminal..

                </div> }

                <Submit 
                
                    step={ { 
                        
                        view: step, 
                        
                        set: setStep 
                    
                    } }
                    
                    errors={ { 
                        
                        view: errors, 
                        
                        set: setErrors 
                        
                    } }
                    
                    order={ CreateOrder } />

            </CheckoutContext.Provider>

        </CheckoutLayout>
        
    </div> );

}

/** Export component */
 
export default Checkout;