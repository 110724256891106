/** Import modules */

import { gql } from "@apollo/client";

const 

/** Tyres collection */

TyresCatalog = gql`query productQuery ( 
    
    $first                  : Int, 
    
    $last                   : Int, 
    
    $after                  : String, 
    
    $before                 : String, 
    
    $width                  : [Int!], 
    
    $height                 : [Float!], 
    
    $diameter               : [Float!] 
    
    $manufacturer           : [String!], 
    
    $season                 : [String!], 

    $speedRating            : [String!],

    $specifications         : [String!],

    $loadIndex              : [String!],

    $noiseIndex             : [String!],

    $wetBreakingEfficiency  : [String!],

    $fuelEfficiency         : [String!],

    $vehicleType            : [String!],
    
    $sortOrder              : String ) {
    
        multiFieldProductSearch ( 
        
            first                   : $first, 
            
            last                    : $last, 
            
            productType             : "tire", 
        
            after                   : $after, 
        
            before                  : $before, 
        
            width                   : $width, 
        
            height                  : $height, 
        
            diameter                : $diameter, 
            
            manufacturer            : $manufacturer, 
            
            season                  : $season, 

            speedRating             : $speedRating,

            specifications          : $specifications,

            loadIndex               : $loadIndex,

            noiseIndex              : $noiseIndex,

            wetBreakingEfficiency   : $wetBreakingEfficiency

            fuelEfficiency          : $fuelEfficiency

            vehicleType             : $vehicleType
            
            sortOrder               : $sortOrder 
            
        ) { 
            
        edges { 
            
            node {

                id

                awsImageUrl

                currentImage

                width

                height

                diameter

                model

                loadIndex

                noiseIndex

                speedRating

                season

                productVendors {

                    price
          
                    qty 
                    
                    qtyDays
                    
                    qtyHour
                    
                    qtyWeeks

                }

                manufacturer { name }

                wetBreakingEfficiency

                fuelEfficiency

                specifications

                vehicleType

                priceList

            } 
    
        }
            
        pageInfo { 
            
            hasNextPage 
            
            hasPreviousPage 
            
            startCursor 
            
            endCursor 
        
        }

    }

}`,

/** Single product */

SingleProduct = gql`query SingleProductQuery ( $id: [ID!]! ) { product ( id: $id ) { 

    edges { node {

        id

        awsImageUrl

        currentImage

        model

        width

        height

        diameter

        loadIndex

        speedRating

        loadIndex

        noiseIndex

        season

        description

        productVendors {

            price
            
            qty 
            
            qtyDays
            
            qtyHour
            
            qtyWeeks

        }

        manufacturer { name }

        vehicleType

        wetBreakingEfficiency

        fuelEfficiency

        specifications

        priceList

    } }
          
} }`,

/** Tyre filter attributes */

FilterAttributes = gql`query { searchAttributes }`;

/** Export queries */

export { 
    
    TyresCatalog, 
    
    FilterAttributes, 
    
    SingleProduct 

};