/** Import modules */

import { Link }                 from "react-router-dom";

/** Import icons */

import { FaShoppingCart }       from 'react-icons/fa';

/** Import misc */

import { RimPermalinks }        from "../../../../../Misc/Permalinks";

/** @import assets */

import NoProduct from "../../../../../Assets/no-rims.jpg";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Catalog list item component
 * 
 */

const ListItem = ( { product } ) => { const permalink = RimPermalinks ( product );

    /** Return component */

    return ( <div className="item">

        <Link to={ permalink }>

            <div className="thumbnail rims">

                { ( ! product.awsImageUrl ) ? <img src={ NoProduct } alt={ product.model } /> : <img src={ product.awsImageUrl } alt={ product.model } /> }

            </div>

        </Link>

        <div className="name">
            
            <div className="model">

                <Link to={ permalink }>

                    <span>
                            
                        { product.manufacturer.name }
    
                    </span>

                    { product.model }

                </Link>

            </div>

            <div className="size">

                { `W: ${ product.width } | R${ product.diameter }` }

            </div>
            
        </div>

        <div className="price">

            <button className="add-to-cart" onClick={ () => console.log( "Add to cart" ) }>

                <FaShoppingCart />

            </button>

            <div className="product-price">
            
                &euro; { parseFloat ( product.priceList.retail_price ).toFixed ( 2 ) }

            </div>
            
        </div>

    </div> );

}

/** Export component */
 
export default ListItem;