/**
 * 
 * @param { product } param0 
 * 
 * @returns Product features component
 * 
 */

const Features = ( { product } ) => { const 

    /** Features list */

    collection = [

        { value: product.season, case: true },

        { value: ( product.specifications.length ) ? "OE" : false, case: true }

    ];

    /** Return component */

    return ( <div className="features">
        
        { collection.map ( ( feature, index ) => <>
        
            { feature.value && 
            
                <div className={ `feature ${ feature.case && "uppercase" }` } key={ index }>

                    { feature.value }

                </div> 

            } 
            
        </> ) }
        
    </div> );

}

/** Export component */
 
export default Features;