/** @import modules */

import { 
    
    BrowserRouter, 
    
    Routes }                from 'react-router-dom';

/** @import contexts */

import { CartContext }      from '../Contexts/CartContext';

import { CompareContext }   from '../Contexts/CompareContext';

/** @import hooks */

import { CartHook }         from '../Hooks/Cart';

import { CompareProducts }  from '../Hooks/Compare';

import ScrollController     from '../Hooks/ScrollController';

/** @import datasets */

import Pages                from "../Datasets/Routes";

/** @import components */

import Header               from '../Components/Layout/Header';

import Footer               from '../Components/Layout/Footer';

/**
 * 
 * @returns Application page component
 * 
 */

const Application = () => {

    return (<BrowserRouter>

        <ScrollController />
    
        <main className="application">

            <CartContext.Provider value={ CartHook () }>

                <CompareContext.Provider value={ CompareProducts () }>

                    <Header />

                    <section className="layout">

                        <Routes>

                            { Pages }

                        </Routes>

                    </section>

                    <Footer />

                </CompareContext.Provider>

            </CartContext.Provider>

        </main>
    
    </BrowserRouter>);

}

/** Export component */

export default Application;
