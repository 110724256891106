/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import Manufactures             from "../Components/Products/Rims/Catalog/Filters/Manufacturers";

import Offset                   from "../Components/Products/Rims/Catalog/Filters/Offset";

const 

/** @return filter widgets */

FiltersCollection = ( data ) => { const { t } = useTranslation (),

    { 

        manufacturers,

        offsets
    
    } = data.searchAttributes.rims[0],

    Manufacturers = [ ...manufacturers ].sort( ( a, b ) => a.text.localeCompare ( b.text ) ),

    Offsets = [ ...offsets ].sort ( ( a, b ) => parseInt ( a.value ) - parseInt ( b.value ) );

    return [

        { 
            
            title: t ( "manufacturer" ), 
        
            content: <Manufactures attributes={ Manufacturers } />, 
        
            toggle: true },

        { 
        
            title: t ( "offset" ), 
        
            content: <Offset attributes={ Offsets } />, 
        
            toggle: true },

    ];

};

/** @export datasets */

export { 
    
    FiltersCollection

}