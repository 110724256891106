const 

TimeConverter = data => { const

    Start = new Date ( 
        
        data.year, 
        
        data.month, 
        
        data.day, 
        
        data.startHour, 
        
        data.startMinute 
        
    ),

    End = new Date ( 
        
        data.year, 
        
        data.month, 
        
        data.day, 
        
        data.endHour, 
        
        data.endMinute 
        
    ),

    Time = {

        StartTime: `${ Start.getHours () }:${ ( Start.getMinutes () < 10 ? '0' : '' ) + Start.getMinutes () }`,

        EndTime: `${ End.getHours () }:${ ( End.getMinutes () < 10 ? '0' : '' ) + End.getMinutes () }`

    };

    return `${ Time.StartTime } - ${ Time.EndTime }`;

},

SingleDateConverter = data => { const 

    date            = new Date ( data ),

    year            = date.getFullYear (),

    month           = date.getMonth () + 1,

    day             = date.getDate (),

    monthString     = ( month < 10 ) ? `0${ month }` : month,

    dayString       = ( day < 10 ) ? `0${ day }` : day;

    /** @return date string */

    return `${ dayString }.${ monthString }.${ year }`;

},

SingleTimeConverter = data => { const 

    date                = new Date ( data ),

    hours               = date.getHours (),

    minutes             = date.getMinutes (),

    hoursString         = ( hours < 10 ) ? `0${ hours }` : hours,

    minutesString       = ( minutes < 10 ) ? `0${ minutes }` : minutes;

    /** @return time string */

    return `${ hoursString }:${ minutesString }`;

};

/** @export */

export {

    TimeConverter,

    SingleDateConverter,

    SingleTimeConverter

}