/** @import modules */

import { useState } from "react";

/**
 * 
 * @returns montage hook
 * 
 */

export const MontageHook = () => { const

    [ autoNumber,           setAutoNumber           ] = useState ( "" ),

    [ autoBrand,            setAutoBrand            ] = useState ( "" ),

    [ autoModel,            setAutoModel            ] = useState ( "" ),

    [ fullName,             setFullName             ] = useState ( "" ),

    [ phoneNumber,          setPhoneNumber          ] = useState ( "" ),

    [ diskDiameter,         setDiskDiameter         ] = useState ( "" ),

    [ email,                setEmail                ] = useState ( "" ),

    [ notes,                setNotes                ] = useState ( "" ),

    [ transportType,        setTransportType        ] = useState ( false ),

    [ tiresStoredNotes,     setTiresStoredNotes     ] = useState ( "" ),

    [ tiresBoughtNotes,     setTiresBoughtNotes     ] = useState ( "" ),

    [ tiresStored,          setTiresStored          ] = useState ( false ),

    [ tiresBought,          setTiresBought          ] = useState ( false ),

    [ startTime,            setStartTime            ] = useState ( "" ),

    [ endTime,              setEndTime              ] = useState ( "" ),
    
    [ SH,                   setSH                   ] = useState ( "" ),
    
    [ SM,                   setSM                   ] = useState ( "" ),
    
    [ EH,                   setEH                   ] = useState ( "" ),
    
    [ EM,                   setEM                   ] = useState ( "" ),
    
    [ timeSelected,         setTimeSelected         ] = useState ( "" ),
    
    [ dateSelected,         setDateSelected         ] = useState ( new Date () ),

    [ dateState,            setDateState            ] = useState ( false ),

    [ onlinePayment,        setOnlinePayment        ] = useState ( false );

    /** @returns hook data */

    return {

        Number:             { view: autoNumber,         set: setAutoNumber          },

        Brand:              { view: autoBrand,          set: setAutoBrand           },

        Model:              { view: autoModel,          set: setAutoModel           },

        Name:               { view: fullName,           set: setFullName            },

        Phone:              { view: phoneNumber,        set: setPhoneNumber         },

        RimsDiameter:       { view: diskDiameter,       set: setDiskDiameter        },

        Email:              { view: email,              set: setEmail               },

        Notes:              { view: notes,              set: setNotes               },

        Type:               { view: transportType,      set: setTransportType       },

        TiresStoredNotes:   { view: tiresStoredNotes,   set: setTiresStoredNotes    },

        TiresBoughtNotes:   { view: tiresBoughtNotes,   set: setTiresBoughtNotes    },

        TiresStored:        { view: tiresStored,        set: setTiresStored         },

        TiresBought:        { view: tiresBought,        set: setTiresBought         },

        Start:              { view: startTime,          set: setStartTime           },

        End:                { view: endTime,            set: setEndTime             },

        SHD:                { view: SH,                 set: setSH                  },

        SMD:                { view: SM,                 set: setSM                  },

        EHD:                { view: EH,                 set: setEH                  },

        EMD:                { view: EM,                 set: setEM                  },

        TimeState:          { view: timeSelected,       set: setTimeSelected        },

        SelectedDate:       { view: dateSelected,       set: setDateSelected        },

        DateState:          { view: dateState,          set: setDateState           },

        Payment:            { view: onlinePayment,      set: setOnlinePayment       }

    };

}