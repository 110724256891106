/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import components */

import Voltages                 from "../Components/Products/Batteries/Catalog/Filters/Voltages";

import Currents                 from "../Components/Products/Batteries/Catalog/Filters/Currents";

import Capacities               from "../Components/Products/Batteries/Catalog/Filters/Capacities";

const 

/** @return filter widgets */

FiltersCollection = ( data ) => { const { t } = useTranslation (),

    { 
        
        voltages, 
        
        currents, 
        
        capacities
    
    } = data.searchAttributes.batteries[0],

    Current     = [ ...currents     ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) ),

    Voltage     = [ ...voltages     ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) ),

    Capacity    = [ ...capacities   ].sort ( ( a, b ) => parseFloat ( a.value ) - parseFloat ( b.value ) );

    return [

        { 
            
            title: t ( "voltages" ), 
        
            content: <Voltages attributes={ Voltage } />, 
        
            toggle: false },

        { 
        
            title: t ( "currents" ), 
        
            content: <Currents attributes={ Current } />, 
        
            toggle: false },

        { 
    
            title: t ( "capacities" ), 
        
            content: <Capacities attributes={ Capacity } />, 
        
            toggle: false }

    ];

};

/** @export datasets */

export { 
    
    FiltersCollection

}