/** @import modules */

import { useTranslation }   from "react-i18next";

import Omniva               from "./Omniva";

import DPD                  from "./DPD";

import Pickup               from "./Pickup";

/**
 * 
 * @param { method }
 *  
 * @returns delivery method selector component
 * 
 */

const DeliveryMethods = ( { method } ) => { const { t } = useTranslation (),

    Methods = ( { delivery } ) => {

        switch ( delivery ) {

            case "pickup"   : return <Pickup />

            case "omniva"   : return <Omniva />

            case "dpd"      : return <DPD />

            default         : return <div className="select-option-placeholder">

                { t ( "select_delivery" ) }

            </div>

        }

    }

    /** @returns */

    return ( <div className="delivery-methods">

        <Methods 
        
            delivery={ method } />

    </div> );

}

/** @exports DeliveryMethods */
 
export default DeliveryMethods;