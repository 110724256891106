/** Import assets */

import "../../../../Styles/Products.css";

/** Import components */

import Filters from "./Filters";

/**
 * 
 * @param { title, children } param0 
 * 
 * @returns Rims catalog layout component
 * 
 */

const RimsLayout = ( { title, children, filters } ) => {

    /** Return component */

    return ( <div className="content product-catalog rims-catalog">
    
        <div className="layout">

            <aside>

                <div className="title">
                
                    <h2>{ title }</h2>
                
                </div>

                <Filters attributes={ filters } />

            </aside>

            <section>{ children }</section>

        </div>
    
    </div> );

}

/** Export component */
 
export default RimsLayout;