/** @import styles */

import "../../../Styles/Calendar.css";

/** @import modules */

import Calendar             from "react-calendar";

import { useContext }       from "react";

import cookies              from "js-cookie";

/** @import contexts */

import { MontageContext }   from "../../../Contexts/MontageContext";

/** @import miscs */

import { scrollElement }    from "../../../Misc/ScrollElement";

/**
 * 
 * @returns montage calendar component
 * 
 */

const MontageCalendar = () => { const 

    { SelectedDate, TimeState, Type, DateState } = useContext ( MontageContext );

    /** @return component */

    return ( <div className="calendar">

        { Type.view &&

            <Calendar 
            
                minDate={ new Date () } 
            
                tileDisabled={ ( { date } ) => date.getDay () === 0 } 
            
                showNeighboringMonth={ false } 

                locale={ cookies.get ( 'i18next' ) || "lv" }
            
                onChange={ date => {

                    SelectedDate.set ( new Date ( date ) );

                    TimeState.set ( "" );

                    DateState.set ( true );

                    scrollElement ( ".datetime" );

                } } /> 
            
        }

    </div> );

}

/** @export MontageCalendar */
 
export default MontageCalendar;