/** Import modules */

import { useTranslation }   from "react-i18next";

import { useParams }        from "react-router-dom";

import { useRef, useState } from "react";

/** Import models */

import Collection           from "../../../Models/RimsCollection";

/** Import hooks */

import Display              from "../../../Hooks/ProductDisplay";

/** Import components */

import Breadcrumbs          from "../../../Components/Layout/Header/Breadcrumbs";

import ComponentLoader      from "../../../Components/Layout/Loaders/Loader";

import RimsLayout           from "../../../Components/Products/Rims/Catalog/Layout";

import Actions              from "../../../Components/Products/Rims/Catalog/Actions";

import Products             from "../../../Components/Products/Rims/Catalog/Collection";

import Pagination           from "../../../Components/Products/Rims/Catalog/Pagination";

/**
 * 
 * @returns Rims product catalog page
 * 
 */

const RimsCatalog = () => { const 

    { loading, data }       = Collection ( useParams ().parameters ),
    
    { t }                   = useTranslation (),

    { layout, setLayout }   = Display (),

    filters                 = useRef ( null ),

    [ 
        
        mobileFilters, 
        
        setMobileFilters ]  = useState ( false ),

    /** Product loader */
    
    ProductCatalog = () => { if ( loading ) return <ComponentLoader />

        return <Products layout={ layout } products={ data.multiFieldProductSearch.edges } />

    };

    console.log( data );

    /** Render component */

    return ( <div className="container max-content-width">

        <Breadcrumbs collection={ [

            { name: t ( "rims" ), URI: "/shop/products/rims" }

        ] } />

        <RimsLayout title={ t ( "rims" ) } filters={ { view: mobileFilters, set: setMobileFilters, element: filters } }>

            <Actions layout={ { view: layout, set: setLayout } } mobile={ { view: mobileFilters, set: setMobileFilters, element: filters } } />

            <ProductCatalog />

            { ! loading && 

                <Pagination pageInfo={ data.multiFieldProductSearch.pageInfo } />

            }

        </RimsLayout>
        
    </div> );

}

/** Export component */
 
export default RimsCatalog;