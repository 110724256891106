/** Import modules */

import { Route }                from 'react-router-dom';

/** Import components */

import Blog                     from "../Pages/Blog/Blog";

import Article                  from "../Pages/Blog/Article";

import About                    from "../Pages/Contents/About";

import Collaboration            from "../Pages/Contents/Collaboration";

import Contacts                 from "../Pages/Contents/Contacts";

import Delivery                 from "../Pages/Contents/Delivery";

import Gallery                  from "../Pages/Contents/Gallery";

import Payments                 from "../Pages/Contents/Payments";

import Services                 from "../Pages/Services/Services";

import SingleService            from '../Pages/Services/Single';

import Home                     from "../Pages/Home/Home";

import Cart                     from "../Pages/Orders/Cart";

import Checkout                 from "../Pages/Orders/Checkout";

import TransactionSuccess       from '../Pages/Orders/Checkout/Success';

import TransactionFailed        from '../Pages/Orders/Checkout/Error';

import Compare                  from "../Pages/Orders/Compare";

import CookiesPolicy            from "../Pages/Policies/Cookies";

import PrivacyPolicy            from "../Pages/Policies/Privacy";

import TermsOfUse               from "../Pages/Policies/Terms";

import Sale                     from "../Pages/Products/Sale/Catalog";

import TyresCatalog             from "../Pages/Products/Tyres/Catalog";

import SingleTyre               from "../Pages/Products/Tyres/Single";

import RimsCatalog              from "../Pages/Products/Rims/Catalog";

import SingleRim                from "../Pages/Products/Rims/Single";

import SingleBattery            from '../Pages/Products/Batteries/Single';

import BatteriesCatalog         from "../Pages/Products/Batteries/Catalog";

import ProductQuickSearch       from "../Pages/Products/Search/QuickSearch";

import Montage                  from '../Pages/Montage/Montage';

import MontageEliminate         from '../Pages/Montage/Eliminate';

import AppointmentSuccessful    from '../Pages/Montage/Success';

/** Register routes */

const Routes = [

    /** Home */

    { 
        
        URI: "/", 
    
        Content: <Home /> },

    { 
        
        URI: "/*", 
    
        Content: <Home /> },

    /** Blog */

    { 
        
        URI: "/blog", 
    
        Content: <Blog
        
            title="Aktualitātes"
        
        /> },

    { 
    
        URI: "/blog/:page", 
    
        Content: <Blog
        
            title="Aktualitātes"
        
        /> },

    { 
        
        URI: "/blog/article/:articleid", 
    
        Content: <Article /> },

    { 
    
        URI: "/blog/article/:articleid/:slug", 
    
        Content: <Article /> },

    /** Contents */

    { 
        
        URI: "/content/about", 
    
        Content: <About 
        
            title="Par mums"

        /> },

    { 
        
        URI: "/content/collaboration", 
    
        Content: <Collaboration 
        
            title="Sadarbības partneri"

        /> },

    { 
        
        URI: "/content/contacts", 
    
        Content: <Contacts 
        
            title="Kontakti"

        /> },

    { 
        
        URI: "/content/delivery", 
    
        Content: <Delivery 
        
            title="Piegādes informācija"

        /> },

    { 
        
        URI: "/content/gallery", 
    
        Content: <Gallery 
        
            title="Galerija"

        /> },

    { 
        
        URI: "/content/payments", 
    
        Content: <Payments 
        
            title="Norēķini"

        /> },

    { 
        
        URI: "/content/services", 
    
        Content: <Services 
        
            title="Pakalpojumi"

        /> },

    { 
    
        URI: "/content/services/:serviceId", 
    
        Content: <SingleService 
        
            title="Pakalpojumi"

        /> },

    /** Orders */

    { 
        
        URI: "/orders/cart", 
    
        Content: <Cart
        
            title="Grozs"
        
        /> },

    { 
        
        URI: "/orders/checkout", 
    
        Content: <Checkout
        
            title="Pirkuma noformēšana"
        
        /> },

    { 
        
        URI: "/orders/compare", 
    
        Content: <Compare
        
            title="Produktu salīdzināšana"
        
        /> },

    { 
        
        URI: "/orders/checkout/receipt", 
    
        Content: <TransactionSuccess
        
            title="Paldies par pirkumu"
        
        /> },

    { 
    
        URI: "/orders/checkout/error", 
    
        Content: <TransactionFailed
        
            title="Transaction failed"
        
        /> },

    /** Policies */

    { 
        
        URI: "/policies/cookie-policy", 
    
        Content: <CookiesPolicy
        
            title="Sīkdatnes"
        
        /> },

    { 
        
        URI: "/policies/privacy-policy", 
    
        Content: <PrivacyPolicy
        
            title="Privātuma politika"
        
        /> },

    { 
        
        URI: "/policies/terms-of-use", 
    
        Content: <TermsOfUse
        
            title="Lietošanas noteikumi"
        
        /> },

    /** Products */

    { 
        
        URI: "/shop/products/tyres", 
    
        Content: <TyresCatalog
        
            title="Riepu katalogs"
        
        /> },

    { 
    
        URI: "/shop/products/tyres/:parameters", 
    
        Content: <TyresCatalog
        
            title="Riepu katalogs"
        
        /> },

    { 
        
        URI: "/shop/tyre/:productId/:manufacturer/:model/:size", 
    
        Content: <SingleTyre /> },

    { 
        
        URI: "/shop/products/rims", 
    
        Content: <RimsCatalog
        
            title="Disku katalogs"
        
        /> },

    { 
    
        URI: "/shop/products/rims/:parameters",
    
        Content: <RimsCatalog
        
            title="Disku katalogs"
        
        /> },

    { 
        
        URI: "/shop/rim/:productId/:manufacturer/:model/:size", 
    
        Content: <SingleRim /> },

    { 
    
        URI: "/shop/battery/:productId/:manufacturer/:model", 
    
        Content: <SingleBattery /> },

    { 
        
        URI: "/shop/products/batteries", 
    
        Content: <BatteriesCatalog
        
            title="Akumulatoru katalogs"
        
        /> },

    { 

        URI: "/shop/products/batteries/:parameters",
    
        Content: <BatteriesCatalog
        
            title="Akumulatoru katalogs"
        
        /> },

    { 
        
        URI: "/shop/battery/:productId", 
    
        Content: <SingleBattery /> },

    { 
        
        URI: "/shop/products/sale", 
    
        Content: <Sale
        
            title="Izpārdošana"
        
        /> },

    { 
        
        URI: "/shop/products/search/:keywords", 
    
        Content: <ProductQuickSearch
        
            title="Meklēšanas rezultāti"
        
        /> },

    { 
    
        URI: "/content/montage", 
    
        Content: <Montage
        
            title="Montage"
        
        /> },

    { 

        URI: "/content/montage/appointment/:uuid/eliminate", 
    
        Content: <MontageEliminate
        
            title="Montage"
        
        /> },

    { 

        URI: "/content/montage/appointment/:uuid/success", 
    
        Content: <AppointmentSuccessful
        
            title="Montage"
        
        /> },

],

/** Generate react routes */

Pages = Object.keys ( Routes ).map ( ( key, index ) =>
    
    <Route path={ Routes[ key ].URI } element={ Routes[ key ].Content } key={ index } />

);

/** Export routes */

export default Pages;