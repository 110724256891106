/** @import modules */

import { gql } from "@apollo/client";

const 

/** Cart products */

CartProducts = gql`query SingleOrdersProduct ( $id: [ID!]! ) {
  
    product ( id: $id ) { 
        
        edges { 
            
            node {
        
                id

                awsImageUrl

                currentImage

                model

                width

                height

                diameter

                loadIndex

                speedRating

                season

                description

                productType

                voltage

                capacity

                current

                productVendors {

                    price
          
                    qty 
                    
                    qtyDays
                    
                    qtyHour
                    
                    qtyWeeks

                }

                manufacturer {

                    name

                }

                priceList
            
            }
            
        } 
    
    }

}`,

CartSummary = gql`query OrderSummary ( $productParams: [JSON!]! ) {

    orderPriceCalculator ( productParams: $productParams )

}`,

OrderOptions = gql`query { orderOptions }`;

/** @export */

export { 
    
    CartProducts,

    CartSummary,

    OrderOptions

};