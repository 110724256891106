/** @import modules */

import { useState, useEffect }  from "react";

import { useTranslation }       from "react-i18next";

/** @import models */

import OrderSummary             from "../../../Models/OrderSummary";

/**
 * 
 * @returns shopping cart summary
 * 
 */

const CartSummary = () => { const { t } = useTranslation (),

    { loading,  data        } = OrderSummary (),

    [ price,    setPrice    ] = useState ( t ( "calculating" ) ),

    [ vat,      setVAT      ] = useState ( t ( "calculating" ) ),

    [ total,    setTotal    ] = useState ( t ( "calculating" ) );

    useEffect ( () => {

        if ( ! loading ) { const 

            totals = Object.values ( data.orderPriceCalculator ).reduce ( ( a, b ) => a + parseFloat ( b.price ), 0 );

            setPrice    ( ( totals - ( totals * 0.21 ) ).toFixed ( 2 )      );

            setVAT      ( ( totals * 0.21 ).toFixed ( 2 )                   );

            setTotal    ( totals.toFixed ( 2 )                              );

        }

    }, [ loading, data ] );

    /** @return component */

    return ( <div className="cart_totals">
            
        <ul>

            <li className="highlighted">{ t ( "cart_subtotals" ) }: { price } &euro;</li>

            <li>{ t ( "cart_vat" ) }: { vat } &euro;</li>

            <li className="highlighted">{ t ( "cart_totals" ) }: { total } &euro;</li>

        </ul>
        
    </div> );

}

/** @export component */
 
export default CartSummary;