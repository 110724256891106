/** @import modules */

import { useState } from "react";

/**
 * 
 * @returns checkout hook
 * 
 */

export const CheckoutHook = () => { const

    [ type,             setType             ] = useState ( "private" ),

    [ company,          setCompany          ] = useState ( "" ),

    [ regno,            setRegNo            ] = useState ( "" ),

    [ vat,              setVAT              ] = useState ( "" ),

    [ name,             setName             ] = useState ( "" ),

    [ lastname,         setLastname         ] = useState ( "" ),

    [ email,            setEmail            ] = useState ( "" ),

    [ phone,            setPhone            ] = useState ( "" ),

    [ delivery,         setDelivery         ] = useState ( "" ),

    [ country,          setCountry          ] = useState ( "" ),

    [ town,             setTown             ] = useState ( "" ),

    [ region,           setRegion           ] = useState ( "" ),

    [ street,           setStreet           ] = useState ( "" ),

    [ streetno,         setStreetNo         ] = useState ( "" ),

    [ postalcode,       setPostalCode       ] = useState ( "" ),

    [ payment,          setPayment          ] = useState ( "" ),
    
    [ policy,           setPolicy           ] = useState ( "" );

    /** @returns hook data */

    return {

        Type:               { view: type,           set: setType            },

        Company:            { view: company,        set: setCompany         },

        RegistrationNo:     { view: regno,          set: setRegNo           },

        VAT:                { view: vat,            set: setVAT             },

        Name:               { view: name,           set: setName            },

        Lastname:           { view: lastname,       set: setLastname        },

        Email:              { view: email,          set: setEmail           },

        Phone:              { view: phone,          set: setPhone           },

        Delivery:           { view: delivery,       set: setDelivery        },

        Country:            { view: country,        set: setCountry         },

        Town:               { view: town,           set: setTown            },

        Region:             { view: region,         set: setRegion          },

        Street:             { view: street,         set: setStreet          },

        StreetNo:           { view: streetno,       set: setStreetNo        },

        Postal:             { view: postalcode,     set: setPostalCode      },

        Payment:            { view: payment,        set: setPayment         },

        Policy:             { view: policy,         set: setPolicy          }

    };

}