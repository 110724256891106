/** @import modules */

import { useTranslation }               from 'react-i18next';

/** @import icons */

import { IoChatbubbleEllipsesSharp }    from 'react-icons/io5';

import { FaShoppingCart }               from 'react-icons/fa';

import { SiGooglestreetview }           from "react-icons/si";

import { FaWaze }                       from "react-icons/fa";

/** Register datasets */

const HeaderDatasets = ( cart, compare ) => { const { t } = useTranslation (),

    Shop = [

        { route: "/content/services",               name: t ( "services" )      },

        { route: "/content/montage",                name: t ( "montage" )       },

        { route: "/blog",               name: t ( "blog" )      },

    ],

    ShopCollection = [

        { route: "/content/services",               name: t ( "services" )      },

        { route: "/content/montage",                name: t ( "montage" )       }

    ],

    Actions = [

        { 
            id          : "contacts",
            
            type        : "labeled", 
        
            name        : t ( "contacts" ), 
        
            icon        : <IoChatbubbleEllipsesSharp />, 
        
            URI         : "/content/contacts", 
        
            counter     : false,
        
            display     : "desktop",
        
            label       : t ( "contacts" ) },
        // { 

        //     id          : "cart",
            
        //     type        : "glyphed", 
        
        //     name        : null, 
        
        //     icon        : <FaShoppingCart />, 
        
        //     URI         : "/orders/cart", 
        
        //     counter     : true,
        
        //     display     : "mobile",
        
        //     label       : t ( "shopping_cart" ) },

    ],

    Locations = [

        { 
            
            icon: <FaWaze />, 
        
            URI: "https://ul.waze.com/ul?place=ChIJU7oCGgDS7kYRWG5njTFSuJI&ll=56.92684850%2C24.18595930&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location" },

        { 
        
            icon: <SiGooglestreetview />, 
        
            URI: "https://g.page/tireshopriga?share" },

    ],

    Hours = [

        { name: t ( "weekdays"  ), content: "09:00 - 18:00" },

        { name: t ( "saturdays" ), content: "10:00 - 16:00" },

        { name: t ( "sundays"   ), content: t ( "closed" )  }

    ];

    return {

        Actions, 
        
        Locations, 
        
        Hours, 
        
        ShopCollection, 
        
        Shop

    }

}

/** Export datasets */

export default HeaderDatasets;