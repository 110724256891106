/** @import modules */

import { useTranslation } from "react-i18next";

/** @import models */

import Product from "../../../Models/SingleTyre";

/** @import components */

import ComponentLoader from "../../Layout/Loaders/Loader";

/** @import datasets */

import { compareProperties } from "../../../Datasets/Compare";

/** @import icons */

import { AiFillCloseCircle } from "react-icons/ai";

/** @import assets */

import NoTyre from "../../../Assets/no-tyres.jpg";

import NoRim from "../../../Assets/no-rims.jpg";

import NoBattery from "../../../Assets/no-batteries.jpg";

/**
 * 
 * @param { id } param0 
 * 
 * @returns compare product item component
 * 
 */

const CompareItem = ( { id = 0, type, actions } ) => { const { t } = useTranslation (),

    { loading, data } = Product ( id ),

    Item = () => { if ( loading ) return <ComponentLoader />

        const product = data.product.edges[ 0 ].node, 

        properties = compareProperties ( type, product ),

        noImage = productType => {

            if ( productType === "tire" ) return NoTyre;

            if ( productType === "rim" ) return NoRim;

            if ( productType === "battery" ) return NoBattery;

        }

        return <div className="item">

            <button className="removeCompare" onClick={ () => {

                actions.view.splice ( actions.view.map ( item => item.id ).indexOf ( id ), 1 );

                return actions.set ( [...actions.view ] );

            } }>

                <AiFillCloseCircle />

            </button>

            <div className="thumbnail">

                { ( ! product.currentImage ) ? <img src={ noImage ( type ) } alt={ product.model } /> : <img src={ product.currentImage } alt={ product.model } /> }

            </div>

            { properties.map ( ( prop, index ) => <div className={ `property ${ prop.slug }` } key={ index }>

                <div className="name">{ t ( prop.name ) }</div>

                <div className="value">{ prop.value }</div>

            </div> ) }

        </div>
        
    };

    /** @return component */

    return ( <Item /> );

}

/** @export CompareItem */
 
export default CompareItem;