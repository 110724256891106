/** @import assets */

import NoProduct from "../../../../Assets/no-batteries.jpg";

/**
 * 
 * @param { data } param0 
 * 
 * @returns Single product image component
 * 
 */

const Thumbnail = ( { data } ) => { const 

    { image, model } = data;

    /** Return component */

    return ( <div className="image">

        { ( ! image ) ? <img src={ NoProduct } alt={ model } /> : <img src={ image } alt={ model } /> }

    </div> );

}

/** Export component */
 
export default Thumbnail; 