/** @import misc */

import { scrollElement } from "../../Misc/ScrollElement";

const 

/** @returns checkout basic information form validation */

basicValidation = ( data, state ) => { const 

    errors = [];

    if ( data.type.view === "private" ) {

        if ( ! data.name.view ) { errors.push ( { msg: "checkout_missing_name" } ); }

        if ( ! data.lastname.view ) { errors.push ( { msg: "checkout_missing_lastname" } ); }

        if ( ! data.email.view ) { errors.push ( { msg: "checkout_missing_email" } ); }

        if ( ! data.phone.view ) { errors.push ( { msg: "checkout_missing_phone" } ); }

    }

    if ( data.type.view === "legal" ) {

        if ( ! data.name.view ) { errors.push ( { msg: "checkout_missing_name" } ); }

        if ( ! data.lastname.view ) { errors.push ( { msg: "checkout_missing_lastname" } ); }

        if ( ! data.email.view ) { errors.push ( { msg: "checkout_missing_email" } ); }

        if ( ! data.phone.view ) { errors.push ( { msg: "checkout_missing_phone" } ); }

        if ( ! data.company.view ) { errors.push ( { msg: "checkout_missing_company" } ); }

        if ( ! data.regno.view ) { errors.push ( { msg: "checkout_missing_registration_number" } ); }

    }

    state.set ( errors );

    if ( errors.length ) { scrollElement ( ".checkout-errors" ) }

    return ( ! errors.length ) ? true : false;

},

/** @returns checkout delivery form validation */

deliveryValidation = ( data, state ) => { const 

    errors = [];

    if ( ! data.delivery_method.view ) { errors.push ( { msg: "checkout_missing_delivery_method" } ); }

    state.set ( errors );

    if ( errors.length ) { scrollElement ( ".checkout-errors" ) }

    return ( ! errors.length ) ? true : false;

},

/** @returns checkout payment form validation */

paymentValidation = ( data, state ) => { const 

    errors = [];

    if ( ! data.method.view ) { errors.push ( { msg: "checkout_missing_payment_method" } ); }

    if ( ! data.policy.view ) { errors.push ( { msg: "checkout_missing_policy" } ); }

    state.set ( errors );

    if ( errors.length ) { scrollElement ( ".checkout-errors" ) }

    return ( ! errors.length ) ? true : false;

};

/** @exports hooks */
 
export { 
    
    basicValidation, 
    
    deliveryValidation, 
    
    paymentValidation 

};