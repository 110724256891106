/** @import modules */

import { useTranslation }       from "react-i18next";

/** @import icons */

import { AiFillThunderbolt }    from "react-icons/ai";

import { FaTruckLoading }       from "react-icons/fa";

const 

/** @returns */

Delivery = ( { product } ) => { const { t } = useTranslation (),

    delivery = [

        { 
            
            name: t ( "express_delivery" ), 
            
            quantity: product.productVendors[0].qtyHour, 
            
            icon: <AiFillThunderbolt />, 
            
            style: "delivery_option accented" 
        
        },

        { 
            
            name: t ( "days_delivery" ), 
            
            quantity: product.productVendors[0].qtyDays, 
            
            icon: <FaTruckLoading />, 
            
            style: "delivery_option default" 
        
        },

        { 
            
            name: t ( "weeks_delivery" ), 
            
            quantity: product.productVendors[0].qtyWeeks, 
            
            icon: <FaTruckLoading />, 
            
            style: "delivery_option default" 
        
        },

    ];

    return ( <div className="delivery">

        { delivery.map ( ( option, index ) => <>

            { option.quantity > 0 &&
            
                <div className={ option.style } key={ index }>

                    <span className="icon">

                        { option.icon }

                    </span>

                    <div className="name">

                        { option.name }

                    </div>

                </div> 

            }
        
        </> ) }

    </div> );

}

/** @exports Delivery */
 
export default Delivery;