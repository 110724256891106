import { gql } from "@apollo/client";

const 

/** Contacts team members */

Team = gql`query { 
  
    contacts {

        id

        image

        title

        firstName

        lastName

        email

        phone

    } 
  
}`,

Contacts = gql`query { contactInfo }`;

/** Export queries */

export { Team, Contacts }