/** Import icons */

import { BsFillSlashCircleFill } from "react-icons/bs";

import { AiOutlineColumnWidth } from "react-icons/ai";

import { useEffect, useState } from "react";

/**
 * 
 * @param { product } param0 
 * 
 * @returns Product properties component
 * 
 */

const Properties = ( { product } ) => { const 
    
    [ width, setWidth ] = useState ( 0 ),

    [ diameter, setDiameter ] = useState ( 0 );

    useEffect ( () => {

        if ( ! product.width ) { setWidth ( false ); } else if ( product.width === 0 ) {
    
        setWidth ( false ); } else { setWidth ( product.width ); }

        if ( ! product.diameter ) { setDiameter ( false ); } else if ( product.diameter === 0 ) {
    
            setDiameter ( false ); } else { setDiameter ( product.diameter ); }

    }, [ width, diameter, product.width, product.diameter ] );

    /** Return component */

    return ( <div className="properties rim-properties">

        { width && 
            
            <>

                <div className="icon"><AiOutlineColumnWidth /></div>

                <div className="value-numeric">

                    { width }

                </div>

            </>

        }

        { diameter && 
            
            <>

                <div className="icon"><BsFillSlashCircleFill /></div>

                <div className="value-numeric">

                    R{ diameter }

                </div>

            </>

        }
        
    </div> );

}

/** Export component */
 
export default Properties;