/** Import assets */

import LiaaImage from "../../Assets/liaa.jpg";

const LIAA = () => {

    /** @return component */
    
    return ( <div className="max-content-width">
        
        <div className="liaa">

            <div className="image">

                <img src={ LiaaImage } alt="liaa" />

            </div>

            <div className="paragraph">

                SIA Tire Market ir 2022.gada 22.augustā noslēdzis līgumu Nr. SKVL-2022/370 ar Latvijas Investīciju un attīstības aģentūru par atbalsta saņemšanu pasākuma “Starptautiskās konkurētspējas veicināšana” ietvaros, ko līdzfinansē Eiropas Reģionālās attīstības fonds.

            </div>

        </div>
    
    </div> );

}

/** @export component */
 
export default LIAA;