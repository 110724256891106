/** Import hooks */

import { Cleaner } from "../Misc/ObjectCleaner";

const

/** @returns URL parameters */

RimsNavigator = ( params, update ) => { const parameters = new URLSearchParams ( params ),

    /** Register attributes */

    attributes = {

        f       : parseInt      ( parameters.get ( "f" ) ),

        l       : parseInt      ( parameters.get ( "l" ) ),

        b       : parameters.get ( "b" ),

        a       : parameters.get ( "a" ),

        m       : parameters.get ( "m" ),
        
        pcd     : parameters.get ( "pcd" ),

        o       : parameters.get ( "o" ),

        sort    : parameters.get ( "sort" )

    };

    /** Optimize, clean & return attributes */

    return Cleaner ( { ...attributes, ...update } );

},

/** @returns Query attributes based on URL parameters */

QueryAttributes = ( params ) => { const parameters = new URLSearchParams ( params );

    /** Optimize, clean & return query attributes */

    return Cleaner ( {

        first                       : parseInt      ( parameters.get ( "f" ) ),

        last                        : parseInt      ( parameters.get ( "l" ) ),

        before                      : parameters.get ( "b" ),

        after                       : parameters.get ( "a" ),

        sortOrder                   : parameters.get ( "sort" ),

        manufacturer                : parameters.get ( "m" ) ? parameters.get ( "m" ).split ( "," ) : [],

        offset                      : parseInt ( parameters.get ( "o" ) ),

    } );

};

/** Export hooks */
 
export { RimsNavigator, QueryAttributes };